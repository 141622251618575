.Timeline {
  margin-top: -$-size-smaller;
}

.Timeline-wrap {
  transition: height $-animation-speed;
}

.Timeline-blokk {
  display: block;
  line-height: $-size-smaller;
  color: $Timeline-blokk-empty-color;

  &.large {
    margin-bottom: 2px;
  }
}
