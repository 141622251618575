.Tabs-content,
.Tabs-nav {
  padding: 0 !important;
}

#{$Tabs-tab} {
  @include desktop {
    cursor: pointer;
  }
}

[data-component='Tabs']:not(.reset) {
  #{$Tabs-panel} {
    @include desktop {
      padding-left: $-size-large;
      padding-right: $-size-larger;
    }
  }

  .Tabs-sidebar {
    @include desktop {
      padding-top: $-size-larger;
    }
  }

  .Tabs-sidebar-title,
  #{$Tabs-tab} {
    padding-right: $-size-large;
    padding-left: $-size-large;
  }

  .Tabs-sidebar-title {
    color: $-gray-lighter;
    padding-top: $-size;
    padding-bottom: $-size-smaller;
    font-size: $-font-size-large;
    font-weight: $-font-weight-1;
  }

  #{$Tabs-tab} {
    border-bottom: 1px dashed $-shade;
    font-size: $-font-size-small;
    padding: $-size-small;

    &:last-child {
      border-bottom: none;
      padding-bottom: $-size;
    }

    &#{$Tabs-tab-selected} {
      font-weight: bold;
    }

    @include desktop {
      opacity: 0.625;
      cursor: pointer;

      &:hover,
      &#{$Tabs-tab-selected} {
        opacity: 1;
      }

      &#{$Tabs-tab-selected} {
        cursor: default;
      }
    }
  }
}
