.absolute-center {
  @include absolute-center;
}
.absolute-left {
  @include absolute-left;
}
.absolute-right {
  @include absolute-right;
}
.antialiased {
  @include antialiased;
}
.background-position-center {
  background-position: center;
}
.background-size-cover {
  background-size: cover;
}
.card {
  @include card;
}
.background-color-gray-darker {
  background-color: $-gray-darker;
}
.background-color-gray-dark {
  background-color: $-gray-dark;
}
.background-color-gray {
  background-color: $-gray;
}
.background-color-gray-light {
  background-color: $-gray-light;
}
.background-color-gray-lighter {
  background-color: $-gray-lighter;
}
.background-color-red {
  background-color: $-red;
}
.background-color-orange {
  background-color: $-orange;
}
.background-color-yellow {
  background-color: $-yellow;
}
.background-color-green {
  background-color: $-green;
}
.background-color-blue {
  background-color: $-blue;
}
.background-color-violet {
  background-color: $-violet;
}
.background-color-white {
  background-color: white;
}
.color-gray-darker {
  color: $-gray-darker;
}
.color-gray-dark {
  color: $-gray-dark;
}
.color-gray {
  color: $-gray;
}
.color-gray-light {
  color: $-gray-light;
}
.color-gray-lighter {
  color: $-gray-lighter;
}
.color-red {
  color: $-red;
}
.color-orange {
  color: $-orange;
}
.color-yellow {
  color: $-yellow;
}
.color-green {
  color: $-green;
}
.color-blue {
  color: $-blue;
}
.color-violet {
  color: $-violet;
}
.color-white {
  color: white;
}
.display-block {
  display: block;
}
.display-flex {
  display: flex;
  align-items: center;
}
.display-flex-justify {
  @include display-flex-justify;
}
.display-inline-block {
  display: inline-block;
}
.fade {
  opacity: 0;
}
.font-size {
  font-size: $-font-size;
}
.font-size-large {
  font-size: $-font-size-large;
}
.font-size-larger {
  font-size: $-font-size-larger;
}
.font-size-small {
  font-size: $-font-size-small;
}
.font-size-smaller {
  font-size: $-font-size-smaller;
}
.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}
.margin-bottom {
  margin-bottom: $-size;
}
.margin-bottom-large {
  margin-bottom: $-size-large;
}
.margin-bottom-larger {
  margin-bottom: $-size-larger;
}
.margin-bottom-small {
  margin-bottom: $-size-small;
}
.margin-bottom-smaller {
  margin-bottom: $-size-smaller;
}
.no-wrap {
  white-space: nowrap;
}
.overlay {
  @include overlay;
}
.position-absolute {
  position: absolute;
}
.position-absolute-1 {
  position: absolute;
  z-index: 1;
}
.position-relative {
  position: relative;
}
.position-relative-1 {
  position: relative;
  z-index: 1;
}
.round {
  border-radius: 50%;
  overflow: hidden;
}
[class*='rotate-'] {
  transform-origin: 50% 50%;
}
.rotate-45 {
  transform: rotate(45deg);
}
.rotate-90 {
  transform: rotate(90deg);
}
.rotate-135 {
  transform: rotate(135deg);
}
.rotate-180 {
  transform: rotate(180deg);
}
.rotate-225 {
  transform: rotate(225deg);
}
.rotate-270 {
  transform: rotate(270deg);
}
.rotate-315 {
  transform: rotate(315deg);
}
.pseudo {
  @include pseudo;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.texture-dots {
  @include texture(dots);
}
.vertical-center {
  @include absolute-vertical;
}
