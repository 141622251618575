.Token-card {
  @include card;

  @include desktop {
    margin: 0 $-size-large $-size-larger;
  }
}

.Token-card-fields {
  padding: $-size;
}

.Token-card-name {
  border-bottom: 1px dashed $-shade;
  color: $-gray-lighter;
  font-size: 0.875em;
  font-weight: $-font-weight-1;
  margin-bottom: $-size-smaller;
  padding: $-size-smaller $-size;
}

.Token-card-wrap {
  @include desktop {
    display: inline-block;
    width: 25%;
  }
}

.Token-field {
  margin-bottom: $-size-small;
}
