.isvg {
  display: block;
  height: 100%;

  svg {
    display: inline-block;
    height: 100%;
    vertical-align: top;
  }

  &.red {
    path {
      fill: $-red;
    }
  }

  &.orange {
    path {
      fill: $-orange;
    }
  }

  &.white {
    path {
      fill: $-white;
    }
  }

  &.rose {
    path {
      fill: $-rose;
    }
  }
}
