$AssignmentCard-employee-id-font-size: 12px;
$AssignmentCard-middle-height: 38px;
$AssignmentCard-shortcut-height: 28px;
$AssignmentCard-shortcut-padding: 4px;
$AssignmentCard-top-height: 30px;
$AssignmentsHeader-height: 40px;
$Task-icon-height: 22px;
$Task-status-inprogress-color: $-blue;
$Task-status-inprogress-color: $-blue;
$Task-action: '.Task-action.round.smaller';
$Task-action-icon-height: 26px;
$Task-action-icon-compressed-height: 8px;
$Timeline-blokk-empty-color: $-gray-lighter;
$AssignmentCard-date-height: 38px;
