$WindmillAnimation-blades-animation-speed: 6s;
$WindmillAnimation-color: $-gray-light;
$WindmillAnimation-font-size: 10px;
$WindmillAnimation-light-animation-speed: 2s;
$WindmillAnimation-light-color: #360a0a;

.WindmillAnimation-engine {
  background: linear-gradient(to bottom, $-gray-lighter, $-gray-light);
  border-radius: 0.5em;
  height: 2em;
  left: 14em;
  position: absolute;
  top: 13em;
  width: 2em;
}

.WindmillAnimation-blades {
  animation: rotatemill $WindmillAnimation-blades-animation-speed linear
    infinite;
  height: 20em;
  left: 5em;
  position: absolute;
  top: 4em;
  width: 20em;

  > div {
    width: 0.625em;
    height: 12em;
    position: absolute;
    background: linear-gradient(to bottom, lighten($-gray, 10%), $-gray);
    border-top-left-radius: 0.5em 2em;

    &:nth-child(1) {
      top: -2em;
      left: 9.7em;
    }

    &:nth-child(2) {
      top: 7em;
      left: 15em;
      transform: rotate(120deg);
    }

    &:nth-child(3) {
      top: 7em;
      left: 4.5em;
      transform: rotate(240deg);
    }
  }
}

.WindmillAnimation-head {
  background: $-gray-lightest;
  border: 1px solid white;
  border-radius: 50%;
  box-shadow: 0 1px 0 $-shade;
  height: 1em;
  left: 14.5em;
  position: absolute;
  top: 13.5em;
  width: 1em;
  z-index: 1000;
}
.WindmillAnimation-inner {
  font-size: $WindmillAnimation-font-size;
  height: 31em;
  margin: 1em auto 0;
  position: relative;
  width: 31em;
  z-index: -10;
}

.WindmillAnimation-light {
  animation: lightbulblight $WindmillAnimation-light-animation-speed linear
    infinite;
  background: $-orange;
  border-radius: 50%;
  height: 1em;
  left: 14.5em;
  position: absolute;
  top: 12.3em;
  width: 1em;
  z-index: -1;
}

.WindmillAnimation-post {
  background: linear-gradient(to bottom, $-gray, lighten($-gray, 10%));
  border-radius: 3em;
  height: 16em;
  left: 14.5em;
  position: absolute;
  top: 14.5em;
  width: 1em;
}

@keyframes rotatemill {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes lightbulblight {
  0% {
    background: $-orange-mute;
    box-shadow: 0 0 0 $-orange-mute;
  }

  50% {
    background: $-orange-neon;
    box-shadow: 0 0 1em $-orange;
  }
}
