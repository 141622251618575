.Infinite-scroll-height {
  @include desktop {
    height: 100vh;
  }
}

.Assignments {
  @include mobile {
    margin-bottom: ($ScrollTop-height * 2);
  }

  @include tablet {
    padding: $-size-larger ($Nav-height - $-size-smaller) 0;
  }

  @include desktop {
    padding: $-size-larger ($Nav-height - $-size-smaller) 0;
  }
}

.Assignments-item {
  padding: 0 $-size-small $-size-small;
  width: 100%;

  &:nth-child(6n + 1) .AssignmentCard-initials {
    background-color: $-red-mute;
  }
  &:nth-child(6n + 2) .AssignmentCard-initials {
    background-color: $-green-mute;
  }
  &:nth-child(6n + 3) .AssignmentCard-initials {
    background-color: $-violet-mute;
  }
  &:nth-child(6n + 4) .AssignmentCard-initials {
    background-color: $-yellow-mute;
  }
  &:nth-child(6n + 5) .AssignmentCard-initials {
    background-color: $-blue-mute;
  }
  &:nth-child(6n + 6) .AssignmentCard-initials {
    background-color: $-orange-mute;
  }

  @include tablet {
    width: 33.33%;
    display: inline-block;
    padding-bottom: $-size-large;
    vertical-align: top;
  }

  @include desktop {
    display: inline-block;
    padding-bottom: $-size-large;
    vertical-align: top;
    width: 25%;
  }
}
