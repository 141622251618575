.AssignmentsHeader {
  z-index: ($Flyer-z + 1);
}

.AssignmentCard-middle {
  z-index: 1;
}

.AssignmentCard-top-content {
  z-index: 2;
}
