.Assignment {
  text-align: center;

  @include mobile {
    margin-bottom: $-size-large;
  }
}

.Assignment-banner {
  @include antialiased;
  color: white;
  text-shadow: 0 0.25px 0.5em $-shade-darker;

  img {
    width: 100%;
  }

  @include desktop {
    margin-bottom: $-size-larger;
  }
}

.Assignment-banner .Banner-content {
  @include desktop {
    padding: $-size-larger;
  }
}

.Assignment-details {
  padding-bottom: $-size-large;

  @include desktop {
    @include display-flex-justify(center);
    border-bottom: 1px dashed rgba(white, 0.15);
    margin-bottom: round($-size-large * 1.5);
    padding-bottom: round($-size-large * 1.25);

    > div {
      margin: 0 round($-size-large * 1.25);
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  @include mobile {
    > div {
      margin-bottom: $-size;
    }
  }
}

.Assignment-label {
  font-size: 1.125em;
  font-weight: $-font-weight-1;
}

.Assignment-value {
  font-family: $-font;
  font-size: $-font-size-larger;
  font-weight: $-font-weight-2;

  small {
    font-size: 1em;
    font-weight: $-font-weight-1;
  }
}

.auditLogsButton {
  &.isExecuting {
    background-color: $-primary-active;
    border: $-primary-active;
  }
}
