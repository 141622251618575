.AssignmentCard {
  @include card;
  position: relative;
  font-size: $-font-size-small;
  color: $-gray;
}

// top

.AssignmentCard-top {
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
  overflow: hidden;
  text-shadow: 0 0.125em 0.5em $-shade-darker;
  color: white;

  &::before {
    @include pseudo(true);
    z-index: 1;
    background-color: rgba($-gray, 0.25);
  }

  .Banner-image {
    &::before {
      @include pseudo(true);
      z-index: 1;
      opacity: 0.625;
      background-color: $-gray-dark;
    }
  }
}

.AssignmentCard-top-content {
  position: relative;
  padding: $AssignmentCard-top-height $-size-large $AssignmentCard-middle-height;
}

.AssignmentCard-shortcuts {
  position: absolute;
  top: 3px;
  right: 3px;
}

.AssignmentCard-shortcut {
  @include cell-sizes($AssignmentCard-shortcut-height);
  padding: $AssignmentCard-shortcut-padding;
  transition: opacity $-animation-speed;

  svg {
    height: 100%;
  }

  @include desktop {
    opacity: 0.375;
  }
}

.AssignmentCard {
  @include desktop {
    &:hover {
      .AssignmentCard-shortcut {
        opacity: 1;
      }
    }
  }
}

.AssignmentCard-shortcut-icon {
  font-size: (
    $AssignmentCard-shortcut-height - ($AssignmentCard-shortcut-padding * 2) -
      2px
  );
}

.AssignmentCard-title {
  @include antialiased;
  font-family: $-font;
  font-style: bold;
  font-weight: $-font-weight-3;
  font-size: 1.5em;
}

.AssignmentCard-duration {
  font-style: italic;
  font-weight: $-font-weight-1;
}

// middle

.AssignmentCard-middle {
  position: relative;
  height: $AssignmentCard-middle-height;
  margin-top: round(-$AssignmentCard-middle-height / 2);
  margin-bottom: $-size;
  margin-left: $-size-large - 2px;
  padding-left: $AssignmentCard-middle-height;
  color: white;
}

.AssignmentCard-initials {
  @include absolute-vertical;
  @include antialiased;
  left: 0;
  width: $AssignmentCard-date-height;
  border: 2px solid white;
  border-radius: 50%;
  box-sizing: content-box;
  text-align: center;
  font-family: $-font;
  font-size: $-font-size;
  font-weight: $-font-weight-2;
  letter-spacing: 0.1em;
  .AssignmnetCard-Month {
    font-size: $-font-size-smaller;
    display: block;
    line-height: $-font-size-smaller;
    padding-top: 7px;
  }
  .AssignmnetCard-Year {
    font-size: $-font-size-smallest;
    display: block;
    line-height: $-font-size-small;
    padding-bottom: 7px;
  }
}

.AssignmentCard-employee {
  position: relative;
  height: $AssignmentCard-middle-height;
  margin-left: $-size-small;
}

.AssignmentCard-employee-name {
  line-height: 1;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  .Assignment-employee-name-span {
    text-overflow: ellipsis;
    max-width: 89%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
  }
}

.AssignmentCard-employee-id {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: $AssignmentCard-employee-id-font-size;
  color: $-gray;
  letter-spacing: 0.025em;

  &::before {
    opacity: 0.625;
    content: '#';
    margin-right: 2px;
  }
}

// bottom

.AssignmentCard-bottom {
  position: relative;
}
