[data-component='Logo'] {
  position: relative;

  &::before {
    @include pseudo(true);
    top: auto;
    border-top: 1px solid $Nav-bg-color;
  }

  a {
    display: block;
    width: $-bar-height;
    height: $-bar-height;
  }

  img {
    height: 100%;
  }
}
