.AssignmentApproval {
  @include display-flex-justify(center);

  > div {
    width: 50%;
  }

  @include desktop {
    > div {
      margin: 0 $-size;

      &:first-child {
        text-align: right;
      }

      &:last-child {
        text-align: left;
      }
    }
  }

  @include mobile {
  }
}

.AssignmentApproval-button {
  background-color: $-shade-dark;
  box-shadow: 0 1px 3px $-shade;
  text-align: center;

  @include desktop {
    width: round($-screen-width-desktop / 7);
  }

  @include mobile {
    width: 100%;
  }
}
