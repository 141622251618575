@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-left {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin absolute-right {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}

@mixin absolute-horizontal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin absolute-vertical() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin antialiased($is: true) {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;

  @if $is == false {
    -webkit-font-smoothing: unset !important;
    -moz-osx-font-smoothing: unset !important;
  }
}

@mixin button-color($color) {
  $darkColor: $color;

  @if ($color != white) {
    $darkColor: darken($color, 10%);
  } @else {
    @include antialiased;
  }

  border-color: $color;
  color: $color;
  path {
    fill: $color;
  }

  &.borderless {
    border-color: transparent;
  }

  &.solid,
  &:hover {
    &:not(.transparentOnHover) {
      background-color: $color;

      @if ($color != white) {
        @include antialiased;
        color: white;
        path {
          fill: white;
        }
      } @else {
        color: $-gray-dark;
        path {
          fill: $-gray-dark;
        }
      }
    }

    &.borderedOnHover {
      border-color: $color;
    }
  }

  &.solid:hover {
    @if ($color != white) {
      background-color: $darkColor;
      border-color: $darkColor;
    }
  }
}

@mixin button-colors($default: $-body-text-color) {
  @include button-color($default);
  &.red {
    @include button-color($-red);
  }
  &.orange {
    @include button-color($-orange);
  }
  &.yellow {
    @include button-color($-yellow);
  }
  &.green {
    @include button-color($-green);
  }
  &.blue {
    @include button-color($-blue);
  }
  &.violet {
    @include button-color($-violet);
  }
  &.light-gray {
    @include button-color($-gray-light);
  }
  &.white {
    @include button-color(white);
  }
}

@mixin card {
  background-color: white;
  border-radius: 1px;
  box-shadow: 0 3px 9px $-shade-lighter, 0 1px 2px $-shade-lighter;
}

@mixin cell-padding($state, $height) {
  transition: padding $-animation-speed;
  padding-top: round($height / 4.125);
  padding-bottom: round($height / 3.875);

  @if $state == padded {
    padding-right: round($height / 3);
    padding-left: round($height / 3);
  }
}

@mixin cell-paddings($state: false, $height: $-cell-height) {
  @include cell-padding($state, $height);

  &.larger {
    @include cell-padding($state, $-cell-height-larger);
  }
  &.large {
    @include cell-padding($state, $-cell-height-large);
  }
  &.small {
    @include cell-padding($state, $-cell-height-small);
  }
  &.smaller {
    @include cell-padding($state, $-cell-height-smaller);
  }
}

@mixin cell-position {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

@mixin cell-size($height) {
  $half: round($height / 2);

  font-size: round($half * 0.7);
  height: $height;
  line-height: $half;

  &.square,
  &.round {
    width: $height;
  }
}

@mixin cell-sizes($height: false) {
  @if $height == false {
    @include cell-size($-cell-height);

    &.default {
      @include antialiased;
    }

    &.larger {
      @include cell-size($-cell-height-larger);
      font-weight: $-font-weight-1;
    }

    &.large {
      @include antialiased;
      @include cell-size($-cell-height-large);
    }

    &.small {
      @include cell-size($-cell-height-small);
    }

    &.smaller {
      @include antialiased;
      @include cell-size($-cell-height-smaller);
      font-weight: $-font-weight-3;
    }
  } @else {
    @include cell-size($height);
  }
}

@mixin display-flex-justify($value: space-between) {
  display: flex;
  justify-content: $value;
}

@mixin desktop {
  @media only screen and (min-width: #{$-screen-width-desktop}) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: #{$-screen-width-tablet}) {
    @content;
  }
}

@mixin field-color($color, $bordered: false) {
  @if $color == false {
    $color: $-gray-dark;
  }

  border: 1px solid transparent;
  border-bottom-color: $color;
  //color: $color;
  transition: border-color $-animation-speed;

  @if $bordered == true {
    border-top: 1px solid $color;
    border-right: 1px solid $color;
    border-left: 1px solid $color;
  }
}

@mixin field-colors($color: $-gray-dark, $bordered: false) {
  @if $color == false {
    $color: $-gray-dark;
  }

  @include field-color($color, $bordered);

  &.red {
    @include field-color($-red, $bordered);
  }
  &.blue {
    @include field-color($-blue, $bordered);
  }
  &.white {
    @include field-color(white, $bordered);
  }
}

@mixin hidden {
  display: none !important;
}

@mixin mobile {
  @media only screen and (max-width: #{$-screen-width-tablet - 1px}) {
    @content;
  }
}

@mixin overlay($position: absolute) {
  position: $position;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin pseudo($overlay: false) {
  content: '';
  display: block;

  @if $overlay == true {
    @include overlay;
  }
}

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    (min--moz-device-pixel-ratio: 2),
    (-o-min-device-pixel-ratio: 2/1),
    (min-device-pixel-ratio: 2),
    (min-resolution: 192dpi),
    (min-resolution: 2dppx) {
    @content;
  }
}

@mixin texture($name) {
  background-repeat: repeat;

  @if $name == 'dots' {
    background-color: #f0f0f0;
    background-image: url('/raster/textures/dots.png');
    background-size: auto 140px;

    @include retina {
      background-image: url('/raster/textures/dots@2X.png');
    }
  }
}
