// Auth
$LockAnimation-width: 140px;
// Col
$Col-spacing: 15px;
// Field
$Field: '[data-component="Field"]';
$Field-selectors: '
.Field-Date > input,
.Field-Number > input,
.Field-Text > input,
.Select-value-label,
textarea
';
$Field-selectors-with-bold-values: '
.Field-Date > input,
.Field-Number > input,
.Field-Text > input,
.Select-value-label
';
$Field-selectors-with-cell-paddings: '
.Field-Date > input,
.Field-Select .Select-input > input,
.Field-Select .Select-value-label,
.Field-Number > input,
.Field-Text > input,
textarea
';
$Field-selectors-with-cell-position: '
.Field-Select .Select-multi-value-wrapper,
.Field-Select .Select-placeholder
';
$Field-selectors-with-cell-sizes: '
.Field-Date > input,
.Field-Select .Select-control,
.Field-Select .Select-input,
.Field-Select .Select-input > input,
.Field-Select .Select-placeholder,
.Field-Select .Select-multi-value-wrapper,
.Field-Select .Select-value,
.Field-Select .Select-value-label,
.Field-Number > input,
.Field-Text > input
';
// DotsAnimation
$DotsAnimation-animation-time: 1.5s;
$DotsAnimation-dots-height: 10px;
$DotsAnimation-dots-height-small: 10px;
// Modal
$Modal-bg-color: rgba($-gray-darkest, 0.95);
// Nav
$Nav-height: $-bar-height;
$Nav-bg-color: $-gray-darker;
// ScrollTop
$ScrollTop-height: 60px;
// Tabs
$Tabs-panel: '.react-tabs__tab-panel';
$Tabs-tab: '.react-tabs__tab';
$Tabs-tab-selected: '.react-tabs__tab--selected';
// Tip
$Tip-arrow-height: 31px;
$Tip-arrow-margin-45: ($Tip-arrow-height / 1.45);
$Tip-arrow-margin-90: ($Tip-arrow-height / 1.2);
$Tip-bg-color: rgba($-gray-darker, 0.9);
