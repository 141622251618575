@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeInDelayed {
  0% {
    opacity: 0; }
  90% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes loading {
  0% {
    opacity: 0;
    transform: translateX(-300px); }
  33% {
    opacity: 1;
    transform: translateX(0px); }
  66% {
    opacity: 1;
    transform: translateX(0px); }
  100% {
    opacity: 0;
    transform: translateX(300px); } }

@keyframes rotate {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

@keyframes slideInHorizontal {
  to {
    transform: translateX(0px);
    opacity: 1; } }

@keyframes slideInVertical {
  to {
    transform: translateY(0px);
    opacity: 1; } }

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.absolute-left {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

.absolute-right {
  position: absolute;
  right: 50%;
  transform: translateX(50%); }

.antialiased {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important; }

.background-position-center {
  background-position: center; }

.background-size-cover {
  background-size: cover; }

.card {
  background-color: white;
  border-radius: 1px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.0625), 0 1px 2px rgba(0, 0, 0, 0.0625); }

.background-color-gray-darker {
  background-color: #393d41; }

.background-color-gray-dark {
  background-color: #4c4e51; }

.background-color-gray {
  background-color: #777879; }

.background-color-gray-light {
  background-color: #999b9c; }

.background-color-gray-lighter {
  background-color: #bbbdbf; }

.background-color-red {
  background-color: #ef6c61; }

.background-color-orange {
  background-color: #ef9a58; }

.background-color-yellow {
  background-color: #ecc663; }

.background-color-green {
  background-color: #48b36b; }

.background-color-blue {
  background-color: #56a1ce; }

.background-color-violet {
  background-color: #a588af; }

.background-color-white {
  background-color: white; }

.color-gray-darker {
  color: #393d41; }

.color-gray-dark {
  color: #4c4e51; }

.color-gray {
  color: #777879; }

.color-gray-light {
  color: #999b9c; }

.color-gray-lighter {
  color: #bbbdbf; }

.color-red {
  color: #ef6c61; }

.color-orange {
  color: #ef9a58; }

.color-yellow {
  color: #ecc663; }

.color-green {
  color: #48b36b; }

.color-blue {
  color: #56a1ce; }

.color-violet {
  color: #a588af; }

.color-white {
  color: white; }

.display-block {
  display: block; }

.display-flex {
  display: flex;
  align-items: center; }

.display-flex-justify {
  display: flex;
  justify-content: space-between; }

.display-inline-block {
  display: inline-block; }

.fade {
  opacity: 0; }

.font-size {
  font-size: 16px; }

.font-size-large {
  font-size: 20px; }

.font-size-larger {
  font-size: 32px; }

.font-size-small {
  font-size: 13px; }

.font-size-smaller {
  font-size: 11px; }

.full-height {
  height: 100%; }

.full-width {
  width: 100%; }

.margin-bottom {
  margin-bottom: 16px; }

.margin-bottom-large {
  margin-bottom: 24px; }

.margin-bottom-larger {
  margin-bottom: 48px; }

.margin-bottom-small {
  margin-bottom: 12px; }

.margin-bottom-smaller {
  margin-bottom: 8px; }

.no-wrap {
  white-space: nowrap; }

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.position-absolute {
  position: absolute; }

.position-absolute-1 {
  position: absolute;
  z-index: 1; }

.position-relative {
  position: relative; }

.position-relative-1 {
  position: relative;
  z-index: 1; }

.round {
  border-radius: 50%;
  overflow: hidden; }

[class*='rotate-'] {
  transform-origin: 50% 50%; }

.rotate-45 {
  transform: rotate(45deg); }

.rotate-90 {
  transform: rotate(90deg); }

.rotate-135 {
  transform: rotate(135deg); }

.rotate-180 {
  transform: rotate(180deg); }

.rotate-225 {
  transform: rotate(225deg); }

.rotate-270 {
  transform: rotate(270deg); }

.rotate-315 {
  transform: rotate(315deg); }

.pseudo {
  content: '';
  display: block; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.texture-dots {
  background-repeat: repeat;
  background-color: #f0f0f0;
  background-image: url("/raster/textures/dots.png");
  background-size: auto 140px; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2 / 1), (min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
    .texture-dots {
      background-image: url("/raster/textures/dots@2X.png"); } }

.vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

*,
*:after,
*:before {
  box-sizing: border-box; }

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none; }

input,
select,
textarea {
  color: inherit; }

* {
  margin: 0;
  padding: 0; }

a {
  color: #56a1ce;
  text-decoration: none; }

html body {
  background-repeat: repeat;
  background-color: #f0f0f0;
  background-image: url("/raster/textures/dots.png");
  background-size: auto 140px;
  width: 100%;
  height: 100%;
  font-family: Inter, Arial, sans-serif;
  font-size: 16px;
  color: #4c4e51; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2 / 1), (min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
    html body {
      background-image: url("/raster/textures/dots@2X.png"); } }

button {
  background-color: transparent;
  font-family: inherit; }

code {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-size: 14px;
  font-weight: bold; }

img {
  display: block; }

input,
textarea {
  display: block;
  font-family: Inter, Arial, sans-serif; }

strong {
  font-weight: 500; }

textarea {
  height: auto !important; }

ul {
  list-style-type: none; }

[data-component='Modal'] {
  z-index: 100; }

[data-component='Nav'] {
  z-index: 90; }

[data-component='Flyer'] {
  z-index: 80; }

[data-component='Tip'] .Tip-bubble {
  z-index: 80; }

[data-component='Banner'] {
  position: relative; }

.Banner-content {
  position: relative;
  z-index: 1; }

.Banner-overlay {
  z-index: 1; }

[data-component='Blokk'] {
  letter-spacing: -0.15em; }
  [data-component='Blokk'].large {
    font-size: 1.75em; }

[data-component='Button'] {
  border-color: #4c4e51;
  color: #4c4e51;
  font-size: 17px;
  height: 48px;
  line-height: 24px;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  line-height: 1;
  text-decoration: none;
  vertical-align: middle; }
  [data-component='Button'] path {
    fill: #4c4e51; }
  [data-component='Button'].borderless {
    border-color: transparent; }
  [data-component='Button'].solid:not(.transparentOnHover), [data-component='Button']:hover:not(.transparentOnHover) {
    background-color: #4c4e51;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    color: white; }
    [data-component='Button'].solid:not(.transparentOnHover) path, [data-component='Button']:hover:not(.transparentOnHover) path {
      fill: white; }
  [data-component='Button'].solid.borderedOnHover, [data-component='Button']:hover.borderedOnHover {
    border-color: #4c4e51; }
  [data-component='Button'].solid:hover {
    background-color: #333537;
    border-color: #333537; }
  [data-component='Button'].red {
    border-color: #ef6c61;
    color: #ef6c61; }
    [data-component='Button'].red path {
      fill: #ef6c61; }
    [data-component='Button'].red.borderless {
      border-color: transparent; }
    [data-component='Button'].red.solid:not(.transparentOnHover), [data-component='Button'].red:hover:not(.transparentOnHover) {
      background-color: #ef6c61;
      -webkit-font-smoothing: antialiased !important;
      -moz-osx-font-smoothing: grayscale !important;
      color: white; }
      [data-component='Button'].red.solid:not(.transparentOnHover) path, [data-component='Button'].red:hover:not(.transparentOnHover) path {
        fill: white; }
    [data-component='Button'].red.solid.borderedOnHover, [data-component='Button'].red:hover.borderedOnHover {
      border-color: #ef6c61; }
    [data-component='Button'].red.solid:hover {
      background-color: #ea4133;
      border-color: #ea4133; }
  [data-component='Button'].orange {
    border-color: #ef9a58;
    color: #ef9a58; }
    [data-component='Button'].orange path {
      fill: #ef9a58; }
    [data-component='Button'].orange.borderless {
      border-color: transparent; }
    [data-component='Button'].orange.solid:not(.transparentOnHover), [data-component='Button'].orange:hover:not(.transparentOnHover) {
      background-color: #ef9a58;
      -webkit-font-smoothing: antialiased !important;
      -moz-osx-font-smoothing: grayscale !important;
      color: white; }
      [data-component='Button'].orange.solid:not(.transparentOnHover) path, [data-component='Button'].orange:hover:not(.transparentOnHover) path {
        fill: white; }
    [data-component='Button'].orange.solid.borderedOnHover, [data-component='Button'].orange:hover.borderedOnHover {
      border-color: #ef9a58; }
    [data-component='Button'].orange.solid:hover {
      background-color: #eb7e29;
      border-color: #eb7e29; }
  [data-component='Button'].yellow {
    border-color: #ecc663;
    color: #ecc663; }
    [data-component='Button'].yellow path {
      fill: #ecc663; }
    [data-component='Button'].yellow.borderless {
      border-color: transparent; }
    [data-component='Button'].yellow.solid:not(.transparentOnHover), [data-component='Button'].yellow:hover:not(.transparentOnHover) {
      background-color: #ecc663;
      -webkit-font-smoothing: antialiased !important;
      -moz-osx-font-smoothing: grayscale !important;
      color: white; }
      [data-component='Button'].yellow.solid:not(.transparentOnHover) path, [data-component='Button'].yellow:hover:not(.transparentOnHover) path {
        fill: white; }
    [data-component='Button'].yellow.solid.borderedOnHover, [data-component='Button'].yellow:hover.borderedOnHover {
      border-color: #ecc663; }
    [data-component='Button'].yellow.solid:hover {
      background-color: #e6b536;
      border-color: #e6b536; }
  [data-component='Button'].green {
    border-color: #48b36b;
    color: #48b36b; }
    [data-component='Button'].green path {
      fill: #48b36b; }
    [data-component='Button'].green.borderless {
      border-color: transparent; }
    [data-component='Button'].green.solid:not(.transparentOnHover), [data-component='Button'].green:hover:not(.transparentOnHover) {
      background-color: #48b36b;
      -webkit-font-smoothing: antialiased !important;
      -moz-osx-font-smoothing: grayscale !important;
      color: white; }
      [data-component='Button'].green.solid:not(.transparentOnHover) path, [data-component='Button'].green:hover:not(.transparentOnHover) path {
        fill: white; }
    [data-component='Button'].green.solid.borderedOnHover, [data-component='Button'].green:hover.borderedOnHover {
      border-color: #48b36b; }
    [data-component='Button'].green.solid:hover {
      background-color: #398f55;
      border-color: #398f55; }
  [data-component='Button'].blue {
    border-color: #56a1ce;
    color: #56a1ce; }
    [data-component='Button'].blue path {
      fill: #56a1ce; }
    [data-component='Button'].blue.borderless {
      border-color: transparent; }
    [data-component='Button'].blue.solid:not(.transparentOnHover), [data-component='Button'].blue:hover:not(.transparentOnHover) {
      background-color: #56a1ce;
      -webkit-font-smoothing: antialiased !important;
      -moz-osx-font-smoothing: grayscale !important;
      color: white; }
      [data-component='Button'].blue.solid:not(.transparentOnHover) path, [data-component='Button'].blue:hover:not(.transparentOnHover) path {
        fill: white; }
    [data-component='Button'].blue.solid.borderedOnHover, [data-component='Button'].blue:hover.borderedOnHover {
      border-color: #56a1ce; }
    [data-component='Button'].blue.solid:hover {
      background-color: #3689bb;
      border-color: #3689bb; }
  [data-component='Button'].violet {
    border-color: #a588af;
    color: #a588af; }
    [data-component='Button'].violet path {
      fill: #a588af; }
    [data-component='Button'].violet.borderless {
      border-color: transparent; }
    [data-component='Button'].violet.solid:not(.transparentOnHover), [data-component='Button'].violet:hover:not(.transparentOnHover) {
      background-color: #a588af;
      -webkit-font-smoothing: antialiased !important;
      -moz-osx-font-smoothing: grayscale !important;
      color: white; }
      [data-component='Button'].violet.solid:not(.transparentOnHover) path, [data-component='Button'].violet:hover:not(.transparentOnHover) path {
        fill: white; }
    [data-component='Button'].violet.solid.borderedOnHover, [data-component='Button'].violet:hover.borderedOnHover {
      border-color: #a588af; }
    [data-component='Button'].violet.solid:hover {
      background-color: #8e6a9a;
      border-color: #8e6a9a; }
  [data-component='Button'].light-gray {
    border-color: #999b9c;
    color: #999b9c; }
    [data-component='Button'].light-gray path {
      fill: #999b9c; }
    [data-component='Button'].light-gray.borderless {
      border-color: transparent; }
    [data-component='Button'].light-gray.solid:not(.transparentOnHover), [data-component='Button'].light-gray:hover:not(.transparentOnHover) {
      background-color: #999b9c;
      -webkit-font-smoothing: antialiased !important;
      -moz-osx-font-smoothing: grayscale !important;
      color: white; }
      [data-component='Button'].light-gray.solid:not(.transparentOnHover) path, [data-component='Button'].light-gray:hover:not(.transparentOnHover) path {
        fill: white; }
    [data-component='Button'].light-gray.solid.borderedOnHover, [data-component='Button'].light-gray:hover.borderedOnHover {
      border-color: #999b9c; }
    [data-component='Button'].light-gray.solid:hover {
      background-color: #7f8283;
      border-color: #7f8283; }
  [data-component='Button'].white {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    border-color: white;
    color: white; }
    [data-component='Button'].white path {
      fill: white; }
    [data-component='Button'].white.borderless {
      border-color: transparent; }
    [data-component='Button'].white.solid:not(.transparentOnHover), [data-component='Button'].white:hover:not(.transparentOnHover) {
      background-color: white;
      color: #4c4e51; }
      [data-component='Button'].white.solid:not(.transparentOnHover) path, [data-component='Button'].white:hover:not(.transparentOnHover) path {
        fill: #4c4e51; }
    [data-component='Button'].white.solid.borderedOnHover, [data-component='Button'].white:hover.borderedOnHover {
      border-color: white; }
  [data-component='Button'].square, [data-component='Button'].round {
    width: 48px; }
  [data-component='Button'].default {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important; }
  [data-component='Button'].larger {
    font-size: 29px;
    height: 84px;
    line-height: 42px;
    font-weight: 300; }
    [data-component='Button'].larger.square, [data-component='Button'].larger.round {
      width: 84px; }
  [data-component='Button'].large {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    font-size: 22px;
    height: 64px;
    line-height: 32px; }
    [data-component='Button'].large.square, [data-component='Button'].large.round {
      width: 64px; }
  [data-component='Button'].small {
    font-size: 13px;
    height: 36px;
    line-height: 18px; }
    [data-component='Button'].small.square, [data-component='Button'].small.round {
      width: 36px; }
  [data-component='Button'].smaller {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    font-size: 10px;
    height: 28px;
    line-height: 14px;
    font-weight: 500; }
    [data-component='Button'].smaller.square, [data-component='Button'].smaller.round {
      width: 28px; }
  [data-component='Button']:not(.round):not(.square) {
    transition: padding 0.2s;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 16px;
    padding-left: 16px; }
    [data-component='Button']:not(.round):not(.square).larger {
      transition: padding 0.2s;
      padding-top: 20px;
      padding-bottom: 22px;
      padding-right: 28px;
      padding-left: 28px; }
    [data-component='Button']:not(.round):not(.square).large {
      transition: padding 0.2s;
      padding-top: 16px;
      padding-bottom: 17px;
      padding-right: 21px;
      padding-left: 21px; }
    [data-component='Button']:not(.round):not(.square).small {
      transition: padding 0.2s;
      padding-top: 9px;
      padding-bottom: 9px;
      padding-right: 12px;
      padding-left: 12px; }
    [data-component='Button']:not(.round):not(.square).smaller {
      transition: padding 0.2s;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-right: 9px;
      padding-left: 9px; }
  [data-component='Button']:not(.round) {
    border-radius: 1px; }
  [data-component='Button'].fluid {
    text-align: center;
    width: 100%; }
  @media only screen and (min-width: 1024px) {
    [data-component='Button'] {
      cursor: pointer; }
      [data-component='Button'].isDisabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.6; }
        [data-component='Button'].isDisabled > * {
          pointer-events: auto; } }

[data-component="Field"] {
  border: 1px solid transparent;
  border-bottom-color: #4c4e51;
  transition: border-color 0.2s;
  position: relative;
  border-radius: 1px; }
  [data-component="Field"].red {
    border: 1px solid transparent;
    border-bottom-color: #ef6c61;
    transition: border-color 0.2s; }
  [data-component="Field"].blue {
    border: 1px solid transparent;
    border-bottom-color: #56a1ce;
    transition: border-color 0.2s; }
  [data-component="Field"].white {
    border: 1px solid transparent;
    border-bottom-color: white;
    transition: border-color 0.2s; }


.Field-Date > input,
.Field-Number > input,
.Field-Text > input,
.Select-value-label,
textarea {
  font-size: 17px;
  height: 48px;
  line-height: 24px;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0; }
  
  .Field-Date > input.square,
  .Field-Date > input.round,
  .Field-Number > input.square,
  .Field-Number > input.round,
  .Field-Text > input.square,
  .Field-Text > input.round,
  .Select-value-label.square,
  .Select-value-label.round,
  textarea.square,
  textarea.round {
    width: 48px; }
  
  .Field-Date > input.default,
  .Field-Number > input.default,
  .Field-Text > input.default,
  .Select-value-label.default,
  textarea.default {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important; }
  
  .Field-Date > input.larger,
  .Field-Number > input.larger,
  .Field-Text > input.larger,
  .Select-value-label.larger,
  textarea.larger {
    font-size: 29px;
    height: 84px;
    line-height: 42px;
    font-weight: 300; }
    
    .Field-Date > input.larger.square,
    .Field-Date > input.larger.round,
    .Field-Number > input.larger.square,
    .Field-Number > input.larger.round,
    .Field-Text > input.larger.square,
    .Field-Text > input.larger.round,
    .Select-value-label.larger.square,
    .Select-value-label.larger.round,
    textarea.larger.square,
    textarea.larger.round {
      width: 84px; }
  
  .Field-Date > input.large,
  .Field-Number > input.large,
  .Field-Text > input.large,
  .Select-value-label.large,
  textarea.large {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    font-size: 22px;
    height: 64px;
    line-height: 32px; }
    
    .Field-Date > input.large.square,
    .Field-Date > input.large.round,
    .Field-Number > input.large.square,
    .Field-Number > input.large.round,
    .Field-Text > input.large.square,
    .Field-Text > input.large.round,
    .Select-value-label.large.square,
    .Select-value-label.large.round,
    textarea.large.square,
    textarea.large.round {
      width: 64px; }
  
  .Field-Date > input.small,
  .Field-Number > input.small,
  .Field-Text > input.small,
  .Select-value-label.small,
  textarea.small {
    font-size: 13px;
    height: 36px;
    line-height: 18px; }
    
    .Field-Date > input.small.square,
    .Field-Date > input.small.round,
    .Field-Number > input.small.square,
    .Field-Number > input.small.round,
    .Field-Text > input.small.square,
    .Field-Text > input.small.round,
    .Select-value-label.small.square,
    .Select-value-label.small.round,
    textarea.small.square,
    textarea.small.round {
      width: 36px; }
  
  .Field-Date > input.smaller,
  .Field-Number > input.smaller,
  .Field-Text > input.smaller,
  .Select-value-label.smaller,
  textarea.smaller {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    font-size: 10px;
    height: 28px;
    line-height: 14px;
    font-weight: 500; }
    
    .Field-Date > input.smaller.square,
    .Field-Date > input.smaller.round,
    .Field-Number > input.smaller.square,
    .Field-Number > input.smaller.round,
    .Field-Text > input.smaller.square,
    .Field-Text > input.smaller.round,
    .Select-value-label.smaller.square,
    .Select-value-label.smaller.round,
    textarea.smaller.square,
    textarea.smaller.round {
      width: 28px; }


.Field-Select .Select-multi-value-wrapper,
.Field-Select .Select-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }


.Field-Date > input,
.Field-Select .Select-control,
.Field-Select .Select-input,
.Field-Select .Select-input > input,
.Field-Select .Select-placeholder,
.Field-Select .Select-multi-value-wrapper,
.Field-Select .Select-value,
.Field-Select .Select-value-label,
.Field-Number > input,
.Field-Text > input {
  font-size: 17px;
  height: 48px;
  line-height: 24px; }
  
  .Field-Date > input.square,
  .Field-Date > input.round,
  .Field-Select .Select-control.square,
  .Field-Select .Select-control.round,
  .Field-Select .Select-input.square,
  .Field-Select .Select-input.round,
  .Field-Select .Select-input > input.square,
  .Field-Select .Select-input > input.round,
  .Field-Select .Select-placeholder.square,
  .Field-Select .Select-placeholder.round,
  .Field-Select .Select-multi-value-wrapper.square,
  .Field-Select .Select-multi-value-wrapper.round,
  .Field-Select .Select-value.square,
  .Field-Select .Select-value.round,
  .Field-Select .Select-value-label.square,
  .Field-Select .Select-value-label.round,
  .Field-Number > input.square,
  .Field-Number > input.round,
  .Field-Text > input.square,
  .Field-Text > input.round {
    width: 48px; }
  
  .Field-Date > input.default,
  .Field-Select .Select-control.default,
  .Field-Select .Select-input.default,
  .Field-Select .Select-input > input.default,
  .Field-Select .Select-placeholder.default,
  .Field-Select .Select-multi-value-wrapper.default,
  .Field-Select .Select-value.default,
  .Field-Select .Select-value-label.default,
  .Field-Number > input.default,
  .Field-Text > input.default {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important; }
  
  .Field-Date > input.larger,
  .Field-Select .Select-control.larger,
  .Field-Select .Select-input.larger,
  .Field-Select .Select-input > input.larger,
  .Field-Select .Select-placeholder.larger,
  .Field-Select .Select-multi-value-wrapper.larger,
  .Field-Select .Select-value.larger,
  .Field-Select .Select-value-label.larger,
  .Field-Number > input.larger,
  .Field-Text > input.larger {
    font-size: 29px;
    height: 84px;
    line-height: 42px;
    font-weight: 300; }
    
    .Field-Date > input.larger.square,
    .Field-Date > input.larger.round,
    .Field-Select .Select-control.larger.square,
    .Field-Select .Select-control.larger.round,
    .Field-Select .Select-input.larger.square,
    .Field-Select .Select-input.larger.round,
    .Field-Select .Select-input > input.larger.square,
    .Field-Select .Select-input > input.larger.round,
    .Field-Select .Select-placeholder.larger.square,
    .Field-Select .Select-placeholder.larger.round,
    .Field-Select .Select-multi-value-wrapper.larger.square,
    .Field-Select .Select-multi-value-wrapper.larger.round,
    .Field-Select .Select-value.larger.square,
    .Field-Select .Select-value.larger.round,
    .Field-Select .Select-value-label.larger.square,
    .Field-Select .Select-value-label.larger.round,
    .Field-Number > input.larger.square,
    .Field-Number > input.larger.round,
    .Field-Text > input.larger.square,
    .Field-Text > input.larger.round {
      width: 84px; }
  
  .Field-Date > input.large,
  .Field-Select .Select-control.large,
  .Field-Select .Select-input.large,
  .Field-Select .Select-input > input.large,
  .Field-Select .Select-placeholder.large,
  .Field-Select .Select-multi-value-wrapper.large,
  .Field-Select .Select-value.large,
  .Field-Select .Select-value-label.large,
  .Field-Number > input.large,
  .Field-Text > input.large {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    font-size: 22px;
    height: 64px;
    line-height: 32px; }
    
    .Field-Date > input.large.square,
    .Field-Date > input.large.round,
    .Field-Select .Select-control.large.square,
    .Field-Select .Select-control.large.round,
    .Field-Select .Select-input.large.square,
    .Field-Select .Select-input.large.round,
    .Field-Select .Select-input > input.large.square,
    .Field-Select .Select-input > input.large.round,
    .Field-Select .Select-placeholder.large.square,
    .Field-Select .Select-placeholder.large.round,
    .Field-Select .Select-multi-value-wrapper.large.square,
    .Field-Select .Select-multi-value-wrapper.large.round,
    .Field-Select .Select-value.large.square,
    .Field-Select .Select-value.large.round,
    .Field-Select .Select-value-label.large.square,
    .Field-Select .Select-value-label.large.round,
    .Field-Number > input.large.square,
    .Field-Number > input.large.round,
    .Field-Text > input.large.square,
    .Field-Text > input.large.round {
      width: 64px; }
  
  .Field-Date > input.small,
  .Field-Select .Select-control.small,
  .Field-Select .Select-input.small,
  .Field-Select .Select-input > input.small,
  .Field-Select .Select-placeholder.small,
  .Field-Select .Select-multi-value-wrapper.small,
  .Field-Select .Select-value.small,
  .Field-Select .Select-value-label.small,
  .Field-Number > input.small,
  .Field-Text > input.small {
    font-size: 13px;
    height: 36px;
    line-height: 18px; }
    
    .Field-Date > input.small.square,
    .Field-Date > input.small.round,
    .Field-Select .Select-control.small.square,
    .Field-Select .Select-control.small.round,
    .Field-Select .Select-input.small.square,
    .Field-Select .Select-input.small.round,
    .Field-Select .Select-input > input.small.square,
    .Field-Select .Select-input > input.small.round,
    .Field-Select .Select-placeholder.small.square,
    .Field-Select .Select-placeholder.small.round,
    .Field-Select .Select-multi-value-wrapper.small.square,
    .Field-Select .Select-multi-value-wrapper.small.round,
    .Field-Select .Select-value.small.square,
    .Field-Select .Select-value.small.round,
    .Field-Select .Select-value-label.small.square,
    .Field-Select .Select-value-label.small.round,
    .Field-Number > input.small.square,
    .Field-Number > input.small.round,
    .Field-Text > input.small.square,
    .Field-Text > input.small.round {
      width: 36px; }
  
  .Field-Date > input.smaller,
  .Field-Select .Select-control.smaller,
  .Field-Select .Select-input.smaller,
  .Field-Select .Select-input > input.smaller,
  .Field-Select .Select-placeholder.smaller,
  .Field-Select .Select-multi-value-wrapper.smaller,
  .Field-Select .Select-value.smaller,
  .Field-Select .Select-value-label.smaller,
  .Field-Number > input.smaller,
  .Field-Text > input.smaller {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    font-size: 10px;
    height: 28px;
    line-height: 14px;
    font-weight: 500; }
    
    .Field-Date > input.smaller.square,
    .Field-Date > input.smaller.round,
    .Field-Select .Select-control.smaller.square,
    .Field-Select .Select-control.smaller.round,
    .Field-Select .Select-input.smaller.square,
    .Field-Select .Select-input.smaller.round,
    .Field-Select .Select-input > input.smaller.square,
    .Field-Select .Select-input > input.smaller.round,
    .Field-Select .Select-placeholder.smaller.square,
    .Field-Select .Select-placeholder.smaller.round,
    .Field-Select .Select-multi-value-wrapper.smaller.square,
    .Field-Select .Select-multi-value-wrapper.smaller.round,
    .Field-Select .Select-value.smaller.square,
    .Field-Select .Select-value.smaller.round,
    .Field-Select .Select-value-label.smaller.square,
    .Field-Select .Select-value-label.smaller.round,
    .Field-Number > input.smaller.square,
    .Field-Number > input.smaller.round,
    .Field-Text > input.smaller.square,
    .Field-Text > input.smaller.round {
      width: 28px; }


.Field-Date > input,
.Field-Select .Select-input > input,
.Field-Select .Select-value-label,
.Field-Number > input,
.Field-Text > input,
textarea {
  transition: padding 0.2s;
  padding-top: 12px;
  padding-bottom: 12px; }
  
  .Field-Date > input.larger,
  .Field-Select .Select-input > input.larger,
  .Field-Select .Select-value-label.larger,
  .Field-Number > input.larger,
  .Field-Text > input.larger,
  textarea.larger {
    transition: padding 0.2s;
    padding-top: 20px;
    padding-bottom: 22px; }
  
  .Field-Date > input.large,
  .Field-Select .Select-input > input.large,
  .Field-Select .Select-value-label.large,
  .Field-Number > input.large,
  .Field-Text > input.large,
  textarea.large {
    transition: padding 0.2s;
    padding-top: 16px;
    padding-bottom: 17px; }
  
  .Field-Date > input.small,
  .Field-Select .Select-input > input.small,
  .Field-Select .Select-value-label.small,
  .Field-Number > input.small,
  .Field-Text > input.small,
  textarea.small {
    transition: padding 0.2s;
    padding-top: 9px;
    padding-bottom: 9px; }
  
  .Field-Date > input.smaller,
  .Field-Select .Select-input > input.smaller,
  .Field-Select .Select-value-label.smaller,
  .Field-Number > input.smaller,
  .Field-Text > input.smaller,
  textarea.smaller {
    transition: padding 0.2s;
    padding-top: 7px;
    padding-bottom: 7px; }

.Field-label,
.Field-input,
.Field-Date > input,
.Field-Number > input,
.Field-Text > input,
.Select-value-label,
textarea {
  transition: all 0.2s; }

.Field-input {
  border-radius: 1px; }

.Field-label {
  pointer-events: none; }
  .Field-label span {
    display: block;
    transition: all 0.325s; }
  .Field-label.hideLabel {
    opacity: 0; }

.Field-validation-error {
  color: #ef6c61;
  font-size: 13px;
  font-style: italic;
  left: 0;
  padding-top: 6px;
  position: absolute;
  right: 0;
  top: 100%; }

[data-component="Field"].hasError .Field-validation-error {
  animation: fadeIn 0.2s 1 ease-in-out;
  animation-fill-mode: forwards; }


[data-component="Field"].hasValue .Field-Date > input,
[data-component="Field"].hasValue .Field-Number > input,
[data-component="Field"].hasValue .Field-Text > input,
[data-component="Field"].hasValue .Select-value-label {
  font-weight: 500; }

[data-component="Field"].isActive .Field-input:not(.transparent) {
  background-color: white; }

[data-component="Field"].isActive.white .Field-input:not(.transparent) {
  color: #4c4e51; }

[data-component="Field"].isBordered {
  border: 1px solid transparent;
  border-bottom-color: #4c4e51;
  transition: border-color 0.2s;
  border-top: 1px solid #4c4e51;
  border-right: 1px solid #4c4e51;
  border-left: 1px solid #4c4e51; }
  [data-component="Field"].isBordered.red {
    border: 1px solid transparent;
    border-bottom-color: #ef6c61;
    transition: border-color 0.2s;
    border-top: 1px solid #ef6c61;
    border-right: 1px solid #ef6c61;
    border-left: 1px solid #ef6c61; }
  [data-component="Field"].isBordered.blue {
    border: 1px solid transparent;
    border-bottom-color: #56a1ce;
    transition: border-color 0.2s;
    border-top: 1px solid #56a1ce;
    border-right: 1px solid #56a1ce;
    border-left: 1px solid #56a1ce; }
  [data-component="Field"].isBordered.white {
    border: 1px solid transparent;
    border-bottom-color: white;
    transition: border-color 0.2s;
    border-top: 1px solid white;
    border-right: 1px solid white;
    border-left: 1px solid white; }
  [data-component="Field"].isBordered .Field-label.onHighlightHideLabel {
    opacity: 0; }
  
  [data-component="Field"].isBordered .Field-Date > input,
  [data-component="Field"].isBordered .Field-Select .Select-input > input,
  [data-component="Field"].isBordered .Field-Select .Select-value-label,
  [data-component="Field"].isBordered .Field-Number > input,
  [data-component="Field"].isBordered .Field-Text > input,
  [data-component="Field"].isBordered textarea {
    transition: padding 0.2s;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 16px;
    padding-left: 16px; }
    
    [data-component="Field"].isBordered .Field-Date > input.larger,
    [data-component="Field"].isBordered .Field-Select .Select-input > input.larger,
    [data-component="Field"].isBordered .Field-Select .Select-value-label.larger,
    [data-component="Field"].isBordered .Field-Number > input.larger,
    [data-component="Field"].isBordered .Field-Text > input.larger,
    [data-component="Field"].isBordered textarea.larger {
      transition: padding 0.2s;
      padding-top: 20px;
      padding-bottom: 22px;
      padding-right: 28px;
      padding-left: 28px; }
    
    [data-component="Field"].isBordered .Field-Date > input.large,
    [data-component="Field"].isBordered .Field-Select .Select-input > input.large,
    [data-component="Field"].isBordered .Field-Select .Select-value-label.large,
    [data-component="Field"].isBordered .Field-Number > input.large,
    [data-component="Field"].isBordered .Field-Text > input.large,
    [data-component="Field"].isBordered textarea.large {
      transition: padding 0.2s;
      padding-top: 16px;
      padding-bottom: 17px;
      padding-right: 21px;
      padding-left: 21px; }
    
    [data-component="Field"].isBordered .Field-Date > input.small,
    [data-component="Field"].isBordered .Field-Select .Select-input > input.small,
    [data-component="Field"].isBordered .Field-Select .Select-value-label.small,
    [data-component="Field"].isBordered .Field-Number > input.small,
    [data-component="Field"].isBordered .Field-Text > input.small,
    [data-component="Field"].isBordered textarea.small {
      transition: padding 0.2s;
      padding-top: 9px;
      padding-bottom: 9px;
      padding-right: 12px;
      padding-left: 12px; }
    
    [data-component="Field"].isBordered .Field-Date > input.smaller,
    [data-component="Field"].isBordered .Field-Select .Select-input > input.smaller,
    [data-component="Field"].isBordered .Field-Select .Select-value-label.smaller,
    [data-component="Field"].isBordered .Field-Number > input.smaller,
    [data-component="Field"].isBordered .Field-Text > input.smaller,
    [data-component="Field"].isBordered textarea.smaller {
      transition: padding 0.2s;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-right: 9px;
      padding-left: 9px; }

[data-component="Field"]:not(.flat) .Field-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: padding 0.2s;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 17px;
  height: 48px;
  line-height: 24px; }
  [data-component="Field"]:not(.flat) .Field-label.larger {
    transition: padding 0.2s;
    padding-top: 20px;
    padding-bottom: 22px; }
  [data-component="Field"]:not(.flat) .Field-label.large {
    transition: padding 0.2s;
    padding-top: 16px;
    padding-bottom: 17px; }
  [data-component="Field"]:not(.flat) .Field-label.small {
    transition: padding 0.2s;
    padding-top: 9px;
    padding-bottom: 9px; }
  [data-component="Field"]:not(.flat) .Field-label.smaller {
    transition: padding 0.2s;
    padding-top: 7px;
    padding-bottom: 7px; }
  [data-component="Field"]:not(.flat) .Field-label.square, [data-component="Field"]:not(.flat) .Field-label.round {
    width: 48px; }
  [data-component="Field"]:not(.flat) .Field-label.default {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important; }
  [data-component="Field"]:not(.flat) .Field-label.larger {
    font-size: 29px;
    height: 84px;
    line-height: 42px;
    font-weight: 300; }
    [data-component="Field"]:not(.flat) .Field-label.larger.square, [data-component="Field"]:not(.flat) .Field-label.larger.round {
      width: 84px; }
  [data-component="Field"]:not(.flat) .Field-label.large {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    font-size: 22px;
    height: 64px;
    line-height: 32px; }
    [data-component="Field"]:not(.flat) .Field-label.large.square, [data-component="Field"]:not(.flat) .Field-label.large.round {
      width: 64px; }
  [data-component="Field"]:not(.flat) .Field-label.small {
    font-size: 13px;
    height: 36px;
    line-height: 18px; }
    [data-component="Field"]:not(.flat) .Field-label.small.square, [data-component="Field"]:not(.flat) .Field-label.small.round {
      width: 36px; }
  [data-component="Field"]:not(.flat) .Field-label.smaller {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    font-size: 10px;
    height: 28px;
    line-height: 14px;
    font-weight: 500; }
    [data-component="Field"]:not(.flat) .Field-label.smaller.square, [data-component="Field"]:not(.flat) .Field-label.smaller.round {
      width: 28px; }
  [data-component="Field"]:not(.flat) .Field-label.animateLabel {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    transform: translateY(-100%); }
    [data-component="Field"]:not(.flat) .Field-label.animateLabel span {
      font-size: 0.875em;
      transform: translateY(-0.25em); }

[data-component="Field"]:not(.flat).hasLabel {
  margin-top: 1.5em;
  margin-bottom: 1.5em; }

[data-component="Field"].flat {
  display: table;
  width: 100%;
  margin-bottom: 16px; }
  [data-component="Field"].flat .Field-label {
    transition: padding 0.2s;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 16px;
    padding-left: 16px;
    padding-left: 0; }
    [data-component="Field"].flat .Field-label.larger {
      transition: padding 0.2s;
      padding-top: 20px;
      padding-bottom: 22px;
      padding-right: 28px;
      padding-left: 28px; }
    [data-component="Field"].flat .Field-label.large {
      transition: padding 0.2s;
      padding-top: 16px;
      padding-bottom: 17px;
      padding-right: 21px;
      padding-left: 21px; }
    [data-component="Field"].flat .Field-label.small {
      transition: padding 0.2s;
      padding-top: 9px;
      padding-bottom: 9px;
      padding-right: 12px;
      padding-left: 12px; }
    [data-component="Field"].flat .Field-label.smaller {
      transition: padding 0.2s;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-right: 9px;
      padding-left: 9px; }
    [data-component="Field"].flat .Field-label span {
      font-size: 1.25em;
      font-weight: 300; }
  [data-component="Field"].flat.hasValue .Field-label span {
    font-weight: 400; }
  @media only screen and (min-width: 1024px) {
    [data-component="Field"].flat {
      margin-bottom: 24px; }
      [data-component="Field"].flat .Field-label-wrap,
      [data-component="Field"].flat .Field-input-wrap {
        display: table-cell;
        vertical-align: middle; }
      [data-component="Field"].flat .Field-label-wrap {
        width: 35%;
        text-align: right; }
      [data-component="Field"].flat .Field-label {
        padding-right: 24px; }
      [data-component="Field"].flat .Field-input-wrap {
        width: 65%; } }

[data-component='Flyer'] {
  text-align: center; }
  [data-component='Flyer'].hasIcon .Flyer-message {
    top: 55%; }
  [data-component='Flyer'].hasMessage .Flyer-icon {
    bottom: 45%; }
  [data-component='Flyer'].hasIcon:not(.hasMessage) .Flyer-icon,
  [data-component='Flyer'].hasMessage:not(.hasIcon) .Flyer-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.Flyer-icon,
.Flyer-message {
  left: 0;
  padding: 8px 24px;
  position: absolute;
  right: 0;
  transition: bottom 0.2s, top 0.2s; }
  @media only screen and (min-width: 1024px) {
    .Flyer-icon,
    .Flyer-message {
      padding: 12px 48px; } }

.Flyer-message {
  color: #777879;
  font-size: 20px;
  font-style: italic;
  font-weight: 700; }

[data-component='Icon'] {
  color: black;
  display: inline-block;
  height: 1em;
  position: relative;
  vertical-align: top;
  width: 100%; }
  [data-component='Icon'] img {
    height: 100%; }
  [data-component='Icon'].red {
    color: #ef6c61; }
    [data-component='Icon'].red path {
      fill: #ef6c61; }
  [data-component='Icon'].orange {
    color: #ef9a58; }
    [data-component='Icon'].orange path {
      fill: #ef9a58; }
  [data-component='Icon'].yellow {
    color: #ecc663; }
    [data-component='Icon'].yellow path {
      fill: #ecc663; }
  [data-component='Icon'].green {
    color: #48b36b; }
    [data-component='Icon'].green path {
      fill: #48b36b; }
  [data-component='Icon'].blue {
    color: #56a1ce; }
    [data-component='Icon'].blue path {
      fill: #56a1ce; }
  [data-component='Icon'].violet {
    color: #a588af; }
    [data-component='Icon'].violet path {
      fill: #a588af; }
  [data-component='Icon'].gray {
    color: #777879; }
    [data-component='Icon'].gray path {
      fill: #777879; }
  [data-component='Icon'].white {
    color: #f3f3f3; }
    [data-component='Icon'].white path {
      fill: #f3f3f3; }
  [data-component='Icon'].rose {
    color: #e86153; }
    [data-component='Icon'].rose path {
      fill: #e86153; }

.Icon-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.Icon-sub {
  position: absolute; }
  .Icon-sub:not(.sub-center) {
    height: 50%; }
  .Icon-sub.sub-center {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .Icon-sub.sub-bottom {
    top: 100%;
    transform: translate(50%, -50%); }
  .Icon-sub.sub-left {
    right: 100%;
    transform: translate(50%, 50%); }
  .Icon-sub.sub-right {
    left: 100%;
    transform: translate(-50%, 50%); }
  .Icon-sub.sub-top {
    bottom: 100%;
    transform: translate(50%, 50%); }

.Icon-Calendar-children {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.35em;
  font-weight: 500;
  line-height: 1;
  padding-top: 0.35em;
  text-transform: uppercase; }

.Icon-thick .Icon-Calendar-children {
  font-weight: 800; }

.Icon-thin .Icon-Calendar-children {
  font-weight: 300; }

[data-component='Logo'] {
  position: relative; }
  [data-component='Logo']::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    top: auto;
    border-top: 1px solid #393d41; }
  [data-component='Logo'] a {
    display: block;
    width: 50px;
    height: 50px; }
  [data-component='Logo'] img {
    height: 100%; }

[data-component='Modal'] {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: rgba(42, 45, 47, 0.95);
  border-radius: 1px;
  bottom: 12px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.0625);
  color: white;
  left: 12px;
  position: fixed;
  right: 12px;
  top: 12px; }

.Modal-close path {
  fill: white; }

@media only screen and (min-width: 1024px) {
  .Modal-close {
    cursor: pointer;
    height: 48px;
    opacity: 0.375;
    position: absolute;
    right: 48px;
    top: 48px; }
    .Modal-close:hover {
      opacity: 1; } }

.Modal-message {
  margin-bottom: 24px;
  opacity: 0.875; }
  @media only screen and (min-width: 1024px) {
    .Modal-message {
      margin-bottom: 48px; } }

.Modal-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px; }
  @media only screen and (min-width: 1024px) {
    .Modal-title {
      font-size: 32px; } }

[data-component='Nav'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #393d41; }
  [data-component='Nav'] .nav__section {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%; }
    [data-component='Nav'] .nav__section + .nav__section {
      margin-left: 30px; }
  [data-component='Nav'] .nav__section--session {
    flex: 1; }
  @media only screen and (max-width: 735px) {
    [data-component='Nav'] .nav__section--search,
    [data-component='Nav'] .nav__section--session,
    [data-component='Nav'] .nav__section--user {
      display: none; } }
  [data-component='Nav'] .nav__section--user {
    margin-right: 24px; }
  [data-component='Nav'] .nav__logo__icon {
    color: #f3f3f3;
    font-size: 20px; }
  [data-component='Nav'] .nav__logo__text {
    margin-left: 30px;
    color: #f3f3f3;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase; }
    [data-component='Nav'] .nav__logo__text:hover {
      text-decoration: none; }
  @media only screen and (max-width: 735px) {
    [data-component='Nav'] {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.125); } }

.Nav-inner {
  position: relative;
  padding-left: 50px; }
  @media only screen and (min-width: 1024px) {
    .Nav-inner {
      text-align: right; } }

.Nav-link {
  cursor: pointer; }
  @media only screen and (min-width: 1024px) {
    .Nav-link {
      opacity: 0.625; }
      .Nav-link:hover {
        opacity: 1; } }

.Nav-logo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  right: auto;
  width: 50px;
  height: 50px; }

@media only screen and (min-width: 1024px) {
  .Nav-left,
  .Nav-right {
    display: inline-block; } }

@media only screen and (max-width: 735px) {
  .Nav-left,
  .Nav-right {
    display: none; } }

.Nav-left-link {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  color: white;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.075em; }
  @media only screen and (min-width: 1024px) {
    .Nav-left-link {
      display: inline-block;
      height: 50px;
      line-height: 50px;
      padding: 0 12px; }
      .Nav-left-link:last-child {
        padding-right: 48px; } }

.Nav-right .Nav-user {
  display: inline-block;
  margin-right: 24px;
  margin-left: 6px;
  vertical-align: bottom; }

.Nav-right .Nav-search {
  display: inline-block;
  margin-right: 6px;
  vertical-align: center; }

.Nav-right .isvg {
  height: 70%;
  margin: 15% 15% 0; }

.Nav-right-link {
  display: inline-block;
  width: 50px;
  height: 50px; }

@media only screen and (max-width: 735px) {
  [data-component='ScrollTop'] > div {
    bottom: 24px !important;
    height: 60px !important;
    right: calc(50% - 30px) !important;
    width: 60px !important; }
    [data-component='ScrollTop'] > div::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.325);
      background-image: url("/vector/outline_arrow.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 60%;
      border-radius: 50%;
      font-size: 30px;
      line-height: 60px;
      opacity: 0.5;
      text-align: center;
      transform: rotate(-90deg); }
    [data-component='ScrollTop'] > div svg {
      height: 60px;
      opacity: 0;
      width: 60px; } }

.Tabs-content,
.Tabs-nav {
  padding: 0 !important; }

@media only screen and (min-width: 1024px) {
  .react-tabs__tab {
    cursor: pointer; } }

@media only screen and (min-width: 1024px) {
  [data-component='Tabs']:not(.reset) .react-tabs__tab-panel {
    padding-left: 24px;
    padding-right: 48px; } }

@media only screen and (min-width: 1024px) {
  [data-component='Tabs']:not(.reset) .Tabs-sidebar {
    padding-top: 48px; } }

[data-component='Tabs']:not(.reset) .Tabs-sidebar-title,
[data-component='Tabs']:not(.reset) .react-tabs__tab {
  padding-right: 24px;
  padding-left: 24px; }

[data-component='Tabs']:not(.reset) .Tabs-sidebar-title {
  color: #bbbdbf;
  padding-top: 16px;
  padding-bottom: 8px;
  font-size: 20px;
  font-weight: 300; }

[data-component='Tabs']:not(.reset) .react-tabs__tab {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.125);
  font-size: 13px;
  padding: 12px; }
  [data-component='Tabs']:not(.reset) .react-tabs__tab:last-child {
    border-bottom: none;
    padding-bottom: 16px; }
  [data-component='Tabs']:not(.reset) .react-tabs__tab.react-tabs__tab--selected {
    font-weight: bold; }
  @media only screen and (min-width: 1024px) {
    [data-component='Tabs']:not(.reset) .react-tabs__tab {
      opacity: 0.625;
      cursor: pointer; }
      [data-component='Tabs']:not(.reset) .react-tabs__tab:hover, [data-component='Tabs']:not(.reset) .react-tabs__tab.react-tabs__tab--selected {
        opacity: 1; }
      [data-component='Tabs']:not(.reset) .react-tabs__tab.react-tabs__tab--selected {
        cursor: default; } }

[data-component='DotsAnimation'] {
  text-align: center; }

.DotsAnimation-inner {
  display: inline-block; }
  .DotsAnimation-inner.isSolid > div:nth-child(n) {
    background: #99d0f1; }
  .DotsAnimation-inner > div {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 3px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: loading;
    animation-timing-function: ease-in-out;
    transform-origin: center; }
    .DotsAnimation-inner > div:nth-child(6) {
      background: #fec396; }
    .DotsAnimation-inner > div:nth-child(5) {
      background: #99d0f1;
      animation-delay: 100ms; }
    .DotsAnimation-inner > div:nth-child(4) {
      background: #f8d98a;
      animation-delay: 200ms; }
    .DotsAnimation-inner > div:nth-child(3) {
      background: #7dcb96;
      animation-delay: 300ms; }
    .DotsAnimation-inner > div:nth-child(2) {
      background: #f6ada6;
      animation-delay: 400ms; }
    .DotsAnimation-inner > div:nth-child(1) {
      background: #c8b6ce;
      animation-delay: 500ms; }

[data-component='HourglassAnimation'] {
  text-align: center; }

.HourglassAnimation-bottom {
  animation: changeGlassColor 2s infinite;
  border-bottom: solid 20px #ef9a58;
  border-left: solid 9px transparent;
  border-right: solid 9px transparent;
  width: 24px; }

.HourglassAnimation-inner {
  animation: rotate 2s infinite;
  display: inline-block; }
  .HourglassAnimation-inner::after, .HourglassAnimation-inner::before {
    content: '';
    display: block;
    background-color: #4c4e51;
    border-radius: 2px;
    height: 4px;
    left: -2px;
    position: absolute;
    right: -2px; }
  .HourglassAnimation-inner::after {
    top: 100%; }
  .HourglassAnimation-inner::before {
    top: 0;
    transform: translateY(-100%); }

.HourglassAnimation-top {
  animation: changeGlassColor 2s infinite;
  border-left: solid 9px transparent;
  border-right: solid 9px transparent;
  border-top: solid 20px rgba(0, 0, 0, 0.125);
  width: 24px; }

@keyframes changeGlassColor {
  0% {
    border-bottom-color: #ef9a58;
    border-top-color: rgba(0, 0, 0, 0.125); }
  50% {
    border-bottom-color: rgba(0, 0, 0, 0.125);
    border-top-color: #ef9a58; }
  100% {
    border-bottom-color: #ef9a58;
    border-top-color: rgba(0, 0, 0, 0.125); } }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(360deg); } }

[data-component='Auth'] div[type='lock']:first-child {
  background-color: #0a838a !important; }

[data-component='LockAnimation'] {
  text-align: center; }

.LockAnimation-bg {
  position: relative;
  z-index: 1; }

.LockAnimation-fg,
.LockAnimation-shine {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.LockAnimation-fg {
  animation-name: turnTheKnob;
  animation-duration: 7.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out; }

.LockAnimation-fg-wrap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  height: 140px;
  width: 140px; }
  .LockAnimation-fg-wrap::before {
    content: '';
    display: block;
    background-color: #008080;
    border-radius: 50%;
    bottom: 3px;
    left: 3px;
    position: absolute;
    right: 3px;
    top: 3px; }
  .LockAnimation-fg-wrap img {
    position: relative;
    z-index: 1; }

.LockAnimation-icon {
  display: inline-block;
  vertical-align: top;
  width: 140px;
  position: relative; }
  .LockAnimation-icon img {
    height: 100%; }

.LockAnimation-shine {
  z-index: 2; }

@keyframes turnTheKnob {
  0% {
    transform: rotate(0); }
  10% {
    transform: rotate(174deg); }
  15% {
    transform: rotate(174deg); }
  30% {
    transform: rotate(-386deg); }
  35% {
    transform: rotate(-386deg); }
  45% {
    transform: rotate(-205deg); }
  50% {
    transform: rotate(-205deg); }
  60% {
    transform: rotate(-253deg); }
  68% {
    transform: rotate(-253deg); }
  81% {
    transform: rotate(101deg); }
  85% {
    transform: rotate(101deg); }
  95% {
    transform: rotate(0deg); } }

.WindmillAnimation-engine {
  background: linear-gradient(to bottom, #bbbdbf, #999b9c);
  border-radius: 0.5em;
  height: 2em;
  left: 14em;
  position: absolute;
  top: 13em;
  width: 2em; }

.WindmillAnimation-blades {
  animation: rotatemill 6s linear infinite;
  height: 20em;
  left: 5em;
  position: absolute;
  top: 4em;
  width: 20em; }
  .WindmillAnimation-blades > div {
    width: 0.625em;
    height: 12em;
    position: absolute;
    background: linear-gradient(to bottom, #919292, #777879);
    border-top-left-radius: 0.5em 2em; }
    .WindmillAnimation-blades > div:nth-child(1) {
      top: -2em;
      left: 9.7em; }
    .WindmillAnimation-blades > div:nth-child(2) {
      top: 7em;
      left: 15em;
      transform: rotate(120deg); }
    .WindmillAnimation-blades > div:nth-child(3) {
      top: 7em;
      left: 4.5em;
      transform: rotate(240deg); }

.WindmillAnimation-head {
  background: #d0d2d3;
  border: 1px solid white;
  border-radius: 50%;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.125);
  height: 1em;
  left: 14.5em;
  position: absolute;
  top: 13.5em;
  width: 1em;
  z-index: 1000; }

.WindmillAnimation-inner {
  font-size: 10px;
  height: 31em;
  margin: 1em auto 0;
  position: relative;
  width: 31em;
  z-index: -10; }

.WindmillAnimation-light {
  animation: lightbulblight 2s linear infinite;
  background: #ef9a58;
  border-radius: 50%;
  height: 1em;
  left: 14.5em;
  position: absolute;
  top: 12.3em;
  width: 1em;
  z-index: -1; }

.WindmillAnimation-post {
  background: linear-gradient(to bottom, #777879, #919292);
  border-radius: 3em;
  height: 16em;
  left: 14.5em;
  position: absolute;
  top: 14.5em;
  width: 1em; }

@keyframes rotatemill {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(-360deg); } }

@keyframes lightbulblight {
  0% {
    background: #e19c66;
    box-shadow: 0 0 0 #e19c66; }
  50% {
    background: #ffb57b;
    box-shadow: 0 0 1em #ef9a58; } }

.isvg {
  display: block;
  height: 100%; }
  .isvg svg {
    display: inline-block;
    height: 100%;
    vertical-align: top; }
  .isvg.red path {
    fill: #ef6c61; }
  .isvg.orange path {
    fill: #ef9a58; }
  .isvg.white path {
    fill: #f3f3f3; }
  .isvg.rose path {
    fill: #e86153; }

[data-app='Initiations'] .mentions {
  margin: 1em 0;
  height: auto !important;
  line-height: 24px; }

[data-app='Initiations'] .mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px; }

[data-app='Initiations'] .mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
  border-radius: 4px !important; }

[data-app='Initiations'] .mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
  border-radius: 4px !important; }

[data-app='Initiations'] .mentions--multiLine .mentions__control {
  font-size: 14pt; }

[data-app='Initiations'] .mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 63px; }

[data-app='Initiations'] .mentions--multiLine .mentions__input {
  border: 1px solid silver;
  border-radius: 4px !important;
  padding: 9px;
  outline: 0; }

[data-app='Initiations'] .mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt; }

[data-app='Initiations'] .mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15); }

[data-app='Initiations'] .mentions__suggestions__item--focused {
  background-color: #cee4e5; }

[data-app='Initiations'] .mentions__mention {
  position: relative;
  font-weight: bold;
  z-index: 1;
  color: #3498db;
  pointer-events: none; }

[data-app='Initiations'] .Assignment {
  text-align: center; }
  @media only screen and (max-width: 735px) {
    [data-app='Initiations'] .Assignment {
      margin-bottom: 24px; } }

[data-app='Initiations'] .Assignment-banner {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  color: white;
  text-shadow: 0 0.25px 0.5em rgba(0, 0, 0, 0.325); }
  [data-app='Initiations'] .Assignment-banner img {
    width: 100%; }
  @media only screen and (min-width: 1024px) {
    [data-app='Initiations'] .Assignment-banner {
      margin-bottom: 48px; } }

@media only screen and (min-width: 1024px) {
  [data-app='Initiations'] .Assignment-banner .Banner-content {
    padding: 48px; } }

[data-app='Initiations'] .Assignment-details {
  padding-bottom: 24px; }
  @media only screen and (min-width: 1024px) {
    [data-app='Initiations'] .Assignment-details {
      display: flex;
      justify-content: center;
      border-bottom: 1px dashed rgba(255, 255, 255, 0.15);
      margin-bottom: 36px;
      padding-bottom: 30px; }
      [data-app='Initiations'] .Assignment-details > div {
        margin: 0 30px; }
      [data-app='Initiations'] .Assignment-details:last-child {
        border-bottom: none;
        margin-bottom: 0; } }
  @media only screen and (max-width: 735px) {
    [data-app='Initiations'] .Assignment-details > div {
      margin-bottom: 16px; } }

[data-app='Initiations'] .Assignment-label {
  font-size: 1.125em;
  font-weight: 300; }

[data-app='Initiations'] .Assignment-value {
  font-family: Inter, Arial, sans-serif;
  font-size: 32px;
  font-weight: 400; }
  [data-app='Initiations'] .Assignment-value small {
    font-size: 1em;
    font-weight: 300; }

[data-app='Initiations'] .auditLogsButton.isExecuting {
  background-color: #0a838a;
  border: #0a838a; }

[data-app='Initiations'] .AssignmentApproval {
  display: flex;
  justify-content: center; }
  [data-app='Initiations'] .AssignmentApproval > div {
    width: 50%; }
  @media only screen and (min-width: 1024px) {
    [data-app='Initiations'] .AssignmentApproval > div {
      margin: 0 16px; }
      [data-app='Initiations'] .AssignmentApproval > div:first-child {
        text-align: right; }
      [data-app='Initiations'] .AssignmentApproval > div:last-child {
        text-align: left; } }

[data-app='Initiations'] .AssignmentApproval-button {
  background-color: rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.125);
  text-align: center; }
  @media only screen and (min-width: 1024px) {
    [data-app='Initiations'] .AssignmentApproval-button {
      width: 146px; } }
  @media only screen and (max-width: 735px) {
    [data-app='Initiations'] .AssignmentApproval-button {
      width: 100%; } }

[data-app='Initiations'] .AssignmentCard {
  background-color: white;
  border-radius: 1px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.0625), 0 1px 2px rgba(0, 0, 0, 0.0625);
  position: relative;
  font-size: 13px;
  color: #777879; }

[data-app='Initiations'] .AssignmentCard-top {
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
  overflow: hidden;
  text-shadow: 0 0.125em 0.5em rgba(0, 0, 0, 0.325);
  color: white; }
  [data-app='Initiations'] .AssignmentCard-top::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(119, 120, 121, 0.25); }
  [data-app='Initiations'] .AssignmentCard-top .Banner-image::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0.625;
    background-color: #4c4e51; }

[data-app='Initiations'] .AssignmentCard-top-content {
  position: relative;
  padding: 30px 24px 38px; }

[data-app='Initiations'] .AssignmentCard-shortcuts {
  position: absolute;
  top: 3px;
  right: 3px; }

[data-app='Initiations'] .AssignmentCard-shortcut {
  font-size: 10px;
  height: 28px;
  line-height: 14px;
  padding: 4px;
  transition: opacity 0.2s; }
  [data-app='Initiations'] .AssignmentCard-shortcut.square, [data-app='Initiations'] .AssignmentCard-shortcut.round {
    width: 28px; }
  [data-app='Initiations'] .AssignmentCard-shortcut svg {
    height: 100%; }
  @media only screen and (min-width: 1024px) {
    [data-app='Initiations'] .AssignmentCard-shortcut {
      opacity: 0.375; } }

@media only screen and (min-width: 1024px) {
  [data-app='Initiations'] .AssignmentCard:hover .AssignmentCard-shortcut {
    opacity: 1; } }

[data-app='Initiations'] .AssignmentCard-shortcut-icon {
  font-size: 18px; }

[data-app='Initiations'] .AssignmentCard-title {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-family: Inter, Arial, sans-serif;
  font-style: bold;
  font-weight: 500;
  font-size: 1.5em; }

[data-app='Initiations'] .AssignmentCard-duration {
  font-style: italic;
  font-weight: 300; }

[data-app='Initiations'] .AssignmentCard-middle {
  position: relative;
  height: 38px;
  margin-top: -19px;
  margin-bottom: 16px;
  margin-left: 22px;
  padding-left: 38px;
  color: white; }

[data-app='Initiations'] .AssignmentCard-initials {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  left: 0;
  width: 38px;
  border: 2px solid white;
  border-radius: 50%;
  box-sizing: content-box;
  text-align: center;
  font-family: Inter, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1em; }
  [data-app='Initiations'] .AssignmentCard-initials .AssignmnetCard-Month {
    font-size: 11px;
    display: block;
    line-height: 11px;
    padding-top: 7px; }
  [data-app='Initiations'] .AssignmentCard-initials .AssignmnetCard-Year {
    font-size: 9px;
    display: block;
    line-height: 13px;
    padding-bottom: 7px; }

[data-app='Initiations'] .AssignmentCard-employee {
  position: relative;
  height: 38px;
  margin-left: 12px; }

[data-app='Initiations'] .AssignmentCard-employee-name {
  line-height: 1;
  letter-spacing: 0.075em;
  text-transform: uppercase; }
  [data-app='Initiations'] .AssignmentCard-employee-name .Assignment-employee-name-span {
    text-overflow: ellipsis;
    max-width: 89%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden; }

[data-app='Initiations'] .AssignmentCard-employee-id {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  color: #777879;
  letter-spacing: 0.025em; }
  [data-app='Initiations'] .AssignmentCard-employee-id::before {
    opacity: 0.625;
    content: '#';
    margin-right: 2px; }

[data-app='Initiations'] .AssignmentCard-bottom {
  position: relative; }

@media only screen and (min-width: 1024px) {
  [data-app='Initiations'] .Infinite-scroll-height {
    height: 100vh; } }

@media only screen and (max-width: 735px) {
  [data-app='Initiations'] .Assignments {
    margin-bottom: 120px; } }

@media only screen and (min-width: 736px) {
  [data-app='Initiations'] .Assignments {
    padding: 48px 42px 0; } }

@media only screen and (min-width: 1024px) {
  [data-app='Initiations'] .Assignments {
    padding: 48px 42px 0; } }

[data-app='Initiations'] .Assignments-item {
  padding: 0 12px 12px;
  width: 100%; }
  [data-app='Initiations'] .Assignments-item:nth-child(6n + 1) .AssignmentCard-initials {
    background-color: #e2776e; }
  [data-app='Initiations'] .Assignments-item:nth-child(6n + 2) .AssignmentCard-initials {
    background-color: #5ba072; }
  [data-app='Initiations'] .Assignments-item:nth-child(6n + 3) .AssignmentCard-initials {
    background-color: #a092a5; }
  [data-app='Initiations'] .Assignments-item:nth-child(6n + 4) .AssignmentCard-initials {
    background-color: #dfc070; }
  [data-app='Initiations'] .Assignments-item:nth-child(6n + 5) .AssignmentCard-initials {
    background-color: #669dbe; }
  [data-app='Initiations'] .Assignments-item:nth-child(6n + 6) .AssignmentCard-initials {
    background-color: #e19c66; }
  @media only screen and (min-width: 736px) {
    [data-app='Initiations'] .Assignments-item {
      width: 33.33%;
      display: inline-block;
      padding-bottom: 24px;
      vertical-align: top; } }
  @media only screen and (min-width: 1024px) {
    [data-app='Initiations'] .Assignments-item {
      display: inline-block;
      padding-bottom: 24px;
      vertical-align: top;
      width: 25%; } }

[data-app='Initiations'] .AssignmentsHeader {
  position: relative;
  text-align: center; }
  @media only screen and (min-width: 1024px) {
    [data-app='Initiations'] .AssignmentsHeader {
      height: 40px; } }
  @media only screen and (max-width: 735px) {
    [data-app='Initiations'] .AssignmentsHeader {
      margin-bottom: 12px; } }

[data-app='Initiations'] .AssignmentsHeader-inner {
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.125);
  transition: background-color 0.2s, box-shadow 0.2s; }

[data-app='Initiations'] .AssignmentsHeader-link {
  color: #bbbdbf;
  display: block;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 1.125;
  padding: 6px 24px;
  text-transform: uppercase; }
  [data-app='Initiations'] .AssignmentsHeader-link.active {
    color: #56a1ce;
    cursor: default; }
  @media only screen and (min-width: 1024px) {
    [data-app='Initiations'] .AssignmentsHeader-link {
      display: inline-block;
      font-size: 13px;
      line-height: 40px;
      padding-bottom: 0;
      padding-top: 0; }
      [data-app='Initiations'] .AssignmentsHeader-link:hover:not(.active) {
        color: #777879; } }

@media only screen and (max-width: 735px) {
  [data-app='Initiations'] .AssignmentsHeader-link-wrap {
    padding: 0.25em 24px; }
    [data-app='Initiations'] .AssignmentsHeader-link-wrap:first-child {
      padding-top: 1em; }
    [data-app='Initiations'] .AssignmentsHeader-link-wrap:last-child {
      padding-bottom: 1em; } }

@media only screen and (min-width: 1024px) {
  [data-app='Initiations'] .AssignmentsHeader-link-wrap {
    display: inline-block;
    vertical-align: top; } }

[data-app='Initiations'] .AssignmentsHeader-inner.sticky {
  background-color: rgba(57, 61, 65, 0.925);
  box-shadow: none;
  left: 0;
  position: fixed;
  width: 100%;
  top: 0; }
  [data-app='Initiations'] .AssignmentsHeader-inner.sticky .AssignmentsHeader-link {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    color: rgba(255, 255, 255, 0.75); }
    [data-app='Initiations'] .AssignmentsHeader-inner.sticky .AssignmentsHeader-link.active {
      color: #7cc7f4; }
  @media only screen and (min-width: 1024px) {
    [data-app='Initiations'] .AssignmentsHeader-inner.sticky .AssignmentsHeader-link:hover:not(.active) {
      color: white; } }

@media only screen and (min-width: 1024px) {
  [data-app='Initiations'] .CreateAssignment-submit {
    margin: 48px 0 96px; } }

[data-app='Initiations'] .CreateAssignment-submit.isSubmitting {
  border-color: #008080;
  color: #008080; }
  [data-app='Initiations'] .CreateAssignment-submit.isSubmitting path {
    fill: #008080; }
  [data-app='Initiations'] .CreateAssignment-submit.isSubmitting.borderless {
    border-color: transparent; }
  [data-app='Initiations'] .CreateAssignment-submit.isSubmitting.solid:not(.transparentOnHover), [data-app='Initiations'] .CreateAssignment-submit.isSubmitting:hover:not(.transparentOnHover) {
    background-color: #008080;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    color: white; }
    [data-app='Initiations'] .CreateAssignment-submit.isSubmitting.solid:not(.transparentOnHover) path, [data-app='Initiations'] .CreateAssignment-submit.isSubmitting:hover:not(.transparentOnHover) path {
      fill: white; }
  [data-app='Initiations'] .CreateAssignment-submit.isSubmitting.solid.borderedOnHover, [data-app='Initiations'] .CreateAssignment-submit.isSubmitting:hover.borderedOnHover {
    border-color: #008080; }
  [data-app='Initiations'] .CreateAssignment-submit.isSubmitting.solid:hover {
    background-color: #004d4d;
    border-color: #004d4d; }

[data-app='Initiations'] .TaxOptions > div:first-child {
  max-width: 1140px; }

@media only screen and (min-width: 1024px) {
  [data-app='Initiations'] .EditAssignment-submit {
    margin: 48px 0 96px; } }

[data-app='Initiations'] .EditAssignment-submit.isSubmitting {
  border-color: #008080;
  color: #008080; }
  [data-app='Initiations'] .EditAssignment-submit.isSubmitting path {
    fill: #008080; }
  [data-app='Initiations'] .EditAssignment-submit.isSubmitting.borderless {
    border-color: transparent; }
  [data-app='Initiations'] .EditAssignment-submit.isSubmitting.solid:not(.transparentOnHover), [data-app='Initiations'] .EditAssignment-submit.isSubmitting:hover:not(.transparentOnHover) {
    background-color: #008080;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    color: white; }
    [data-app='Initiations'] .EditAssignment-submit.isSubmitting.solid:not(.transparentOnHover) path, [data-app='Initiations'] .EditAssignment-submit.isSubmitting:hover:not(.transparentOnHover) path {
      fill: white; }
  [data-app='Initiations'] .EditAssignment-submit.isSubmitting.solid.borderedOnHover, [data-app='Initiations'] .EditAssignment-submit.isSubmitting:hover.borderedOnHover {
    border-color: #008080; }
  [data-app='Initiations'] .EditAssignment-submit.isSubmitting.solid:hover {
    background-color: #004d4d;
    border-color: #004d4d; }

[data-app='Initiations'] .Token-card {
  background-color: white;
  border-radius: 1px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.0625), 0 1px 2px rgba(0, 0, 0, 0.0625); }
  @media only screen and (min-width: 1024px) {
    [data-app='Initiations'] .Token-card {
      margin: 0 24px 48px; } }

[data-app='Initiations'] .Token-card-fields {
  padding: 16px; }

[data-app='Initiations'] .Token-card-name {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.125);
  color: #bbbdbf;
  font-size: 0.875em;
  font-weight: 300;
  margin-bottom: 8px;
  padding: 8px 16px; }

@media only screen and (min-width: 1024px) {
  [data-app='Initiations'] .Token-card-wrap {
    display: inline-block;
    width: 25%; } }

[data-app='Initiations'] .Token-field {
  margin-bottom: 12px; }

[data-app='Initiations'] .Task-wrap {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.0875); }
  [data-app='Initiations'] .Task-wrap:last-child {
    border-bottom: none; }

[data-app='Initiations'] .Task {
  line-height: 1.15;
  padding: 8px 12px 8px 16px; }
  @media only screen and (min-width: 1024px) {
    [data-app='Initiations'] .Task .Task-action.round.smaller {
      width: 8px;
      height: 8px; } }

[data-app='Initiations'] .Task-inner {
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: row;
  align-items: center;
  transition: opacity 0.25s; }

[data-app='Initiations'] .Task-icon-wrap {
  padding-left: 12px;
  padding-right: 12px;
  text-align: center;
  min-width: 46px; }

[data-app='Initiations'] .Task-icon {
  opacity: 0.625;
  width: 22px;
  height: 22px; }
  [data-app='Initiations'] .Task-icon img,
  [data-app='Initiations'] .Task-icon .isvg {
    height: 100%; }

[data-app='Initiations'] .Task-text {
  flex-grow: 1; }

[data-app='Initiations'] .Task-title {
  opacity: 0.75;
  margin-bottom: 2px;
  font-size: 11px; }
  [data-app='Initiations'] .Task-title .Task-title-label {
    padding-right: 0.15rem; }

[data-app='Initiations'] .Task-due-date {
  font-size: 11px;
  font-weight: 300; }
  [data-app='Initiations'] .Task-due-date.isOverdue {
    font-style: italic; }

[data-app='Initiations'] .Task-actions {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  right: 0;
  white-space: nowrap;
  text-align: right;
  padding-left: 16px; }

[data-app='Initiations'] .Task-action-wrap {
  display: inline-block;
  margin-left: 6px; }
  [data-app='Initiations'] .Task-action-wrap:first-child {
    margin-left: 0; }

[data-app='Initiations'] .Task-action.round.smaller {
  transition: all 0.2s; }
  [data-app='Initiations'] .Task-action.round.smaller:hover .isvg, [data-app='Initiations'] .Task-action.round.smaller.solid .isvg {
    opacity: 1; }
  [data-app='Initiations'] .Task-action.round.smaller:hover path,
  [data-app='Initiations'] .Task-action.round.smaller:hover polygon, [data-app='Initiations'] .Task-action.round.smaller.solid path,
  [data-app='Initiations'] .Task-action.round.smaller.solid polygon {
    fill: white; }
  @media only screen and (min-width: 1024px) {
    [data-app='Initiations'] .Task-action.round.smaller .Task-action-icon {
      opacity: 0;
      transition: opacity 0.2s; } }

[data-app='Initiations'] .Task-action-icon {
  height: 70%;
  width: 70%; }

@media only screen and (min-width: 1024px) {
  [data-app='Initiations'] .Task:hover .Task-action.round.smaller,
  [data-app='Initiations'] .Task-action.round.smaller.isExecuting {
    width: 26px;
    height: 26px; }
    [data-app='Initiations'] .Task:hover .Task-action.round.smaller .Task-action-icon,
    [data-app='Initiations'] .Task-action.round.smaller.isExecuting .Task-action-icon {
      opacity: 1; } }

[data-app='Initiations'] .Task-action.round.smaller.isExecuting {
  border-color: #008080;
  color: #008080;
  color: #ffffff; }
  [data-app='Initiations'] .Task-action.round.smaller.isExecuting path {
    fill: #008080; }
  [data-app='Initiations'] .Task-action.round.smaller.isExecuting.borderless {
    border-color: transparent; }
  [data-app='Initiations'] .Task-action.round.smaller.isExecuting.solid:not(.transparentOnHover), [data-app='Initiations'] .Task-action.round.smaller.isExecuting:hover:not(.transparentOnHover) {
    background-color: #008080;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    color: white; }
    [data-app='Initiations'] .Task-action.round.smaller.isExecuting.solid:not(.transparentOnHover) path, [data-app='Initiations'] .Task-action.round.smaller.isExecuting:hover:not(.transparentOnHover) path {
      fill: white; }
  [data-app='Initiations'] .Task-action.round.smaller.isExecuting.solid.borderedOnHover, [data-app='Initiations'] .Task-action.round.smaller.isExecuting:hover.borderedOnHover {
    border-color: #008080; }
  [data-app='Initiations'] .Task-action.round.smaller.isExecuting.solid:hover {
    background-color: #004d4d;
    border-color: #004d4d; }

[data-app='Initiations'] .Task.hasActions .Task-inner {
  padding-right: 65px; }

[data-app='Initiations'] .Task.hasIcon {
  padding-left: 0; }

[data-app='Initiations'] .Task.isEmpty {
  padding-top: 12px;
  padding-bottom: 12px; }
  [data-app='Initiations'] .Task.isEmpty .Task-icon {
    opacity: 1;
    position: relative; }
    [data-app='Initiations'] .Task.isEmpty .Task-icon::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 19px;
      height: 19px;
      border: 1px solid #bbbdbf;
      border-radius: 50%; }

[data-app='Initiations'] .Task.status-completed:not(:hover) .Task-inner {
  opacity: 0.5; }

[data-app='Initiations'] .Task.status-inprogress .Task-inner {
  color: #56a1ce; }

[data-app='Initiations'] .Task.status-inprogress .Task-icon {
  opacity: 1; }
  [data-app='Initiations'] .Task.status-inprogress .Task-icon path {
    fill: #56a1ce; }

[data-app='Initiations'] .Task-renderedDueDate {
  color: #ef6c61; }

[data-app='Initiations'] .Timeline {
  margin-top: -8px; }

[data-app='Initiations'] .Timeline-wrap {
  transition: height 0.2s; }

[data-app='Initiations'] .Timeline-blokk {
  display: block;
  line-height: 8px;
  color: #bbbdbf; }
  [data-app='Initiations'] .Timeline-blokk.large {
    margin-bottom: 2px; }

[data-app='Initiations'] .AssignmentsHeader {
  z-index: 81; }

[data-app='Initiations'] .AssignmentCard-middle {
  z-index: 1; }

[data-app='Initiations'] .AssignmentCard-top-content {
  z-index: 2; }

@font-face {
  font-family: 'Inter';
  src: url("/fonts/Inter/Inter-VariableFont.ttf") format("truetype"), url("/fonts/Inter/Inter-VariableFont.ttf") format("truetype"); }
