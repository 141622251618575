[data-component='Nav'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: $Nav-height;
  background-color: $Nav-bg-color;

  .nav__section {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    & + .nav__section {
      margin-left: 30px;
    }
  }

  .nav__section--session {
    flex: 1;
  }

  .nav__section--search,
  .nav__section--session,
  .nav__section--user {
    @include mobile {
      display: none;
    }
  }

  .nav__section--user {
    margin-right: $-size-large;
  }

  .nav__logo__icon {
    color: $-white;
    font-size: 20px;
  }

  .nav__logo__text {
    margin-left: 30px;
    color: $-white;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;

    &:hover {
      text-decoration: none;
    }
  }

  @include mobile {
    box-shadow: 0 1px 3px $-shade;
  }
}

//

.Nav-inner {
  position: relative;
  padding-left: $Nav-height;

  @include desktop {
    text-align: right;
  }
}

.Nav-link {
  cursor: pointer;

  @include desktop {
    opacity: 0.625;

    &:hover {
      opacity: 1;
    }
  }
}

.Nav-logo {
  @include overlay;
  right: auto;
  width: $Nav-height;
  height: $Nav-height;
}

.Nav-left,
.Nav-right {
  @include desktop {
    display: inline-block;
  }

  @include mobile {
    display: none; // temporary
  }
}

// left

.Nav-left {
}

.Nav-left-link {
  @include antialiased;
  color: white;
  font-size: $-font-size-small;
  text-transform: uppercase;
  letter-spacing: 0.075em;

  @include desktop {
    display: inline-block;
    height: $Nav-height;
    line-height: $Nav-height;
    padding: 0 $-size-small;

    &:last-child {
      padding-right: $-size-larger;
    }
  }
}

// right

.Nav-right {
  .Nav-user {
    display: inline-block;
    margin-right: $-size-large;
    margin-left: $-size-smallest;
    vertical-align: bottom;
  }

  .Nav-search {
    display: inline-block;
    margin-right: $-size-smallest;
    vertical-align: center;
  }

  .isvg {
    height: 70%;
    margin: 15% 15% 0;
  }
}

.Nav-right-link {
  display: inline-block;
  width: $Nav-height;
  height: $Nav-height;

  @include desktop {
  }
}
