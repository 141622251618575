// COLOR
// shade
$-shade-darkest: rgba(black, 0.5);
$-shade-darker: rgba(black, 0.325);
$-shade-dark: rgba(black, 0.175);
$-shade: rgba(black, 0.125);
$-shade-light: rgba(black, 0.0875);
$-shade-lighter: rgba(black, 0.0625);
$-shade-lightest: rgba(black, 0.0375);
// gray
$-gray-darkest: #2a2d2f;
$-gray-darker: #393d41;
$-gray-dark: #4c4e51;
$-gray: #777879;
$-gray-light: #999b9c;
$-gray-lighter: #bbbdbf;
$-gray-lightest: #d0d2d3;
// hue
$-red: #ef6c61;
$-teal: #008080;
$-primary-active: #0a838a;
$-orange: #ef9a58;
$-yellow: #ecc663;
$-green: #48b36b;
$-blue: #56a1ce;
$-violet: #a588af;
$-white: #f3f3f3;
$-rose: #e86153;
// hue: dark
$-red-dark: darken(desaturate($-red, 25%), 10%);
$-orange-dark: darken(desaturate($-orange, 15%), 5%);
$-yellow-dark: darken(desaturate($-yellow, 10%), 5%);
$-green-dark: darken(desaturate($-green, 5%), 10%);
$-blue-dark: darken(desaturate($-blue, 10%), 5%);
$-violet-dark: darken(desaturate($-violet, 10%), 10%);
// hue: light
$-red-light: lighten($-red, 15%);
$-orange-light: lighten(saturate($-orange, 15%), 15%);
$-yellow-light: lighten(saturate($-yellow, 10%), 10%);
$-green-light: lighten($-green, 15%);
$-blue-light: lighten(saturate($-blue, 20%), 20%);
$-violet-light: lighten($-violet, 15%);
// hue: mute
$-red-mute: desaturate($-red, 15%);
$-orange-mute: desaturate($-orange, 15%);
$-yellow-mute: desaturate($-yellow, 15%);
$-green-mute: desaturate($-green, 15%);
$-blue-mute: desaturate($-blue, 15%);
$-violet-mute: desaturate($-violet, 10%);
// hue: neon
$-red-neon: lighten(saturate($-red, 40%), 20%);
$-orange-neon: lighten(saturate($-orange, 50%), 10%);
$-yellow-neon: lighten(saturate($-yellow, 25%), 5%);
$-green-neon: lighten(saturate($-green, 25%), 25%);
$-blue-neon: lighten(saturate($-blue, 30%), 15%);
$-violet-neon: lighten(saturate($-violet, 25%), 5%);

// DEFAULT
$-animation-speed-faster: 0.1s;
$-animation-speed-fast: 0.15s;
$-animation-speed: 0.2s;
$-animation-speed-slow: 0.25s;
$-animation-speed-slower: 0.325s;

$-bar-height: 50px;
$-bar-height-small: 40px;

$-body-bg-color: $-white;
$-body-text-color: $-gray-dark;

$-cell-height-smaller: 28px;
$-cell-height-small: 36px;
$-cell-height: 48px;
$-cell-height-large: 64px;
$-cell-height-larger: 84px;

$-gutter: 30px;
$-gutter-half: round($-gutter / 2);

$-screen-width-mobile: 320px;
$-screen-width-tablet: 736px;
$-screen-width-desktop: 1024px;

$-size-smallest: 6px;
$-size-smaller: 8px;
$-size-small: 12px;
$-size: 16px;
$-size-large: 24px;
$-size-larger: 48px;
$-size-largest: 96px;

// TYPE
$-font-stack: Inter, Arial, sans-serif;
$-font: #{$-font-stack};

$-font-size-smallest: 9px;
$-font-size-smaller: 11px;
$-font-size-small: 13px;
$-font-size: $-size;
$-font-size-large: 20px;
$-font-size-larger: 32px;
$-font-size-largest: 46px;

$-font-weight-1: 300;
$-font-weight-2: 400;
$-font-weight-3: 500;
$-font-weight-4: 700;
$-font-weight-5: 800;
