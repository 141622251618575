[data-component='Icon'] {
  color: black;
  display: inline-block;
  height: 1em;
  position: relative;
  vertical-align: top;
  width: 100%;

  img {
    height: 100%;
  }

  &.red {
    color: $-red;
    path {
      fill: $-red;
    }
  }
  &.orange {
    color: $-orange;
    path {
      fill: $-orange;
    }
  }
  &.yellow {
    color: $-yellow;
    path {
      fill: $-yellow;
    }
  }
  &.green {
    color: $-green;
    path {
      fill: $-green;
    }
  }
  &.blue {
    color: $-blue;
    path {
      fill: $-blue;
    }
  }
  &.violet {
    color: $-violet;
    path {
      fill: $-violet;
    }
  }
  &.gray {
    color: $-gray;
    path {
      fill: $-gray;
    }
  }
  &.white {
    color: $-white;
    path {
      fill: $-white;
    }
  }
  &.rose {
    color: $-rose;
    path {
      fill: $-rose;
    }
  }
}

.Icon-overlay {
  @include overlay;
}

.Icon-sub {
  position: absolute;

  &:not(.sub-center) {
    height: 50%;
  }

  &.sub-center {
    @include overlay;
  }

  &.sub-bottom {
    top: 100%;
    transform: translate(50%, -50%);
  }
  &.sub-left {
    right: 100%;
    transform: translate(50%, 50%);
  }
  &.sub-right {
    left: 100%;
    transform: translate(-50%, 50%);
  }
  &.sub-top {
    bottom: 100%;
    transform: translate(50%, 50%);
  }
}

// special

.Icon-Calendar-children {
  @include absolute-center;
  font-size: 0.35em;
  font-weight: $-font-weight-3;
  line-height: 1;
  padding-top: 0.35em;
  text-transform: uppercase;
}

.Icon-thick .Icon-Calendar-children {
  font-weight: $-font-weight-5;
}

.Icon-thin .Icon-Calendar-children {
  font-weight: $-font-weight-1;
}
