$HourglassAnimation-animation-speed: 2s;
$HourglassAnimation-cap-height: 4px;
$HourglassAnimation-color-empty: $-shade;
$HourglassAnimation-color-full: $-orange;
$HourglassAnimation-height: 20px;
$HourglassAnimation-width: $-size-large;

$HourglassAnimation-angle: 9px;
$HourglassAnimation-cap-border-radius: floor(
  $HourglassAnimation-cap-height / 2
);

[data-component='HourglassAnimation'] {
  text-align: center;
}

.HourglassAnimation-bottom {
  animation: changeGlassColor $HourglassAnimation-animation-speed infinite;
  border-bottom: solid $HourglassAnimation-height $HourglassAnimation-color-full;
  border-left: solid $HourglassAnimation-angle transparent;
  border-right: solid $HourglassAnimation-angle transparent;
  width: $HourglassAnimation-width;
}

.HourglassAnimation-inner {
  animation: rotate $HourglassAnimation-animation-speed infinite;
  display: inline-block;

  &::after,
  &::before {
    @include pseudo;
    background-color: $-gray-dark;
    border-radius: $HourglassAnimation-cap-border-radius;
    height: $HourglassAnimation-cap-height;
    left: -$HourglassAnimation-cap-border-radius;
    position: absolute;
    right: -$HourglassAnimation-cap-border-radius;
  }

  &::after {
    top: 100%;
  }

  &::before {
    top: 0;
    transform: translateY(-100%);
  }
}

.HourglassAnimation-top {
  animation: changeGlassColor $HourglassAnimation-animation-speed infinite;
  border-left: solid $HourglassAnimation-angle transparent;
  border-right: solid $HourglassAnimation-angle transparent;
  border-top: solid $HourglassAnimation-height $HourglassAnimation-color-empty;
  width: $HourglassAnimation-width;
}

@keyframes changeGlassColor {
  0% {
    border-bottom-color: $HourglassAnimation-color-full;
    border-top-color: $HourglassAnimation-color-empty;
  }

  50% {
    border-bottom-color: $HourglassAnimation-color-empty;
    border-top-color: $HourglassAnimation-color-full;
  }

  100% {
    border-bottom-color: $HourglassAnimation-color-full;
    border-top-color: $HourglassAnimation-color-empty;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
