[data-component='Flyer'] {
  text-align: center;

  // states

  &.hasIcon .Flyer-message {
    top: 55%;
  }

  &.hasMessage .Flyer-icon {
    bottom: 45%;
  }

  &.hasIcon:not(.hasMessage) .Flyer-icon,
  &.hasMessage:not(.hasIcon) .Flyer-message {
    @include absolute-center;
  }
}

.Flyer-icon,
.Flyer-message {
  left: 0;
  padding: $-size-smaller $-size-large;
  position: absolute;
  right: 0;
  transition: bottom $-animation-speed, top $-animation-speed;

  @include desktop {
    padding: $-size-small $-size-larger;
  }
}

.Flyer-message {
  color: $-gray;
  font-size: $-font-size-large;
  font-style: italic;
  font-weight: $-font-weight-4;
}
