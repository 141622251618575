.mentions {
  margin: 1em 0;
  height: auto !important;
  line-height: 24px;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}

.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
  border-radius: 4px !important;
}

.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
  border-radius: 4px !important;
}

.mentions--multiLine .mentions__control {
  font-size: 14pt;
}

.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 63px;
}

.mentions--multiLine .mentions__input {
  border: 1px solid silver;
  border-radius: 4px !important;
  padding: 9px;
  outline: 0;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention {
  position: relative;
  font-weight: bold;
  z-index: 1;
  color: #3498db;
  pointer-events: none;
}
