$Modal-z: 100;
$Nav-z: 90;
$Flyer-z: 80;
$Tip-z: 80;

[data-component='Modal'] {
  z-index: $Modal-z;
}
[data-component='Nav'] {
  z-index: $Nav-z;
}
[data-component='Flyer'] {
  z-index: $Flyer-z;
}
[data-component='Tip'] .Tip-bubble {
  z-index: $Tip-z;
}
