.Task-wrap {
  border-bottom: 1px dashed $-shade-light;

  &:last-child {
    border-bottom: none;
  }
}

.Task {
  line-height: 1.15;
  padding: $-size-smaller $-size-small $-size-smaller $-size;

  @include desktop {
    #{$Task-action} {
      width: $Task-action-icon-compressed-height;
      height: $Task-action-icon-compressed-height;
    }
  }
}

.Task-inner {
  @include display-flex-justify;
  position: relative;
  flex-direction: row;
  align-items: center;
  transition: opacity $-animation-speed-slow;
}

// left

.Task-icon-wrap {
  padding-left: $-size-small;
  padding-right: $-size-small;
  text-align: center;
  min-width: 46px;
}

.Task-icon {
  opacity: 0.625;
  width: $Task-icon-height;
  height: $Task-icon-height;

  img,
  .isvg {
    height: 100%;
  }
}

// text

.Task-text {
  flex-grow: 1;
}

.Task-title {
  opacity: 0.75;
  margin-bottom: 2px;
  font-size: $-font-size-smaller;
  .Task-title-label {
    padding-right: 0.15rem;
  }
}

.Task-due-date {
  font-size: $-font-size-smaller;
  font-weight: $-font-weight-1;

  &.isOverdue {
    font-style: italic;
  }
}

// actions

.Task-actions {
  @include absolute-vertical;
  z-index: 1;
  right: 0;
  white-space: nowrap;
  text-align: right;
  padding-left: $-size;
}

.Task-action-wrap {
  display: inline-block;
  margin-left: $-size-smallest;

  &:first-child {
    margin-left: 0;
  }
}

#{$Task-action} {
  transition: all $-animation-speed;

  &:hover,
  &.solid {
    .isvg {
      opacity: 1;
    }

    path,
    polygon {
      fill: white;
    }
  }

  @include desktop {
    .Task-action-icon {
      opacity: 0;
      transition: opacity $-animation-speed;
    }
  }
}

.Task-action-icon {
  height: 70%;
  width: 70%;
}

// states

.Task:hover #{$Task-action},
#{$Task-action}.isExecuting {
  @include desktop {
    width: $Task-action-icon-height;
    height: $Task-action-icon-height;

    .Task-action-icon {
      opacity: 1;
    }
  }
}

#{$Task-action}.isExecuting {
  @include button-color($-teal);
  color: #ffffff;
}

.Task {
  &.hasActions {
    .Task-inner {
      padding-right: round($Task-action-icon-height * 2.5);
    }
  }

  &.hasIcon {
    padding-left: 0;
  }

  &.isEmpty {
    padding-top: $-size-small;
    padding-bottom: $-size-small;

    .Task-icon {
      opacity: 1;
      position: relative;

      &::before {
        @include pseudo;
        @include absolute-center;
        width: $Task-icon-height - 3px;
        height: $Task-icon-height - 3px;
        border: 1px solid $Timeline-blokk-empty-color;
        border-radius: 50%;
      }
    }
  }

  &.status-completed {
    &:not(:hover) {
      .Task-inner {
        opacity: 0.5;
      }
    }
  }

  &.status-inprogress {
    .Task-inner {
      color: $Task-status-inprogress-color;
    }

    .Task-icon {
      opacity: 1;

      path {
        fill: $Task-status-inprogress-color;
      }
    }
  }
}
.Task-renderedDueDate {
  color: $-red;
}
