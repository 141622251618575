.CreateAssignment-submit {
  @include desktop {
    margin: $-size-larger 0 $-size-largest;
  }

  &.isSubmitting {
    @include button-color($-teal);
  }
}

.TaxOptions {
  > div {
    &:first-child {
      max-width: 1140px;
    }
  }
}
