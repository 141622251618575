[data-component='Banner'] {
  position: relative;
}

.Banner-content {
  position: relative;
  z-index: 1;
}

.Banner-overlay {
  z-index: 1;
}
