#{$Field} {
  @include field-colors;
  position: relative;
  border-radius: 1px;
}

#{$Field-selectors} {
  @include cell-sizes;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
}

#{$Field-selectors-with-cell-position} {
  @include cell-position;
}
#{$Field-selectors-with-cell-sizes} {
  @include cell-sizes;
}
#{$Field-selectors-with-cell-paddings} {
  @include cell-paddings;
}

.Field-label,
.Field-input,
#{$Field-selectors} {
  transition: all $-animation-speed;
}

.Field-input {
  border-radius: 1px;
}

.Field-label {
  pointer-events: none;

  span {
    display: block;
    transition: all $-animation-speed-slower;
  }

  &.hideLabel {
    opacity: 0;
  }
}

.Field-validation-error {
  color: $-red;
  font-size: $-font-size-small;
  font-style: italic;
  left: 0;
  padding-top: $-size-smallest;
  position: absolute;
  right: 0;
  top: 100%;
}

// states

#{$Field}.hasError {
  .Field-validation-error {
    animation: fadeIn $-animation-speed 1 ease-in-out;
    animation-fill-mode: forwards;
  }
}

#{$Field}.hasValue {
  #{$Field-selectors-with-bold-values} {
    font-weight: $-font-weight-3;
  }
}

#{$Field}.isActive {
  .Field-input {
    &:not(.transparent) {
      background-color: white;
    }
  }

  &.white {
    .Field-input {
      &:not(.transparent) {
        color: $-gray-dark;
      }
    }
  }
}

#{$Field}.isBordered {
  @include field-colors(false, true);

  .Field-label.onHighlightHideLabel {
    opacity: 0;
  }

  #{$Field-selectors-with-cell-paddings} {
    @include cell-paddings(padded);
  }
}

// layout

#{$Field}:not(.flat) {
  .Field-label {
    @include absolute-vertical;
    @include cell-paddings(true);
    @include cell-sizes;

    &.animateLabel {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      transform: translateY(-100%);

      span {
        font-size: 0.875em;
        transform: translateY(-0.25em);
      }
    }
  }

  &.hasLabel {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}

#{$Field}.flat {
  display: table;
  width: 100%;
  margin-bottom: $-size;

  .Field-label {
    @include cell-paddings(padded);
    padding-left: 0;

    span {
      font-size: 1.25em;
      font-weight: $-font-weight-1;
    }
  }

  &.hasValue {
    .Field-label {
      span {
        font-weight: $-font-weight-2;
      }
    }
  }

  @include desktop {
    margin-bottom: $-size-large;

    .Field-label-wrap,
    .Field-input-wrap {
      display: table-cell;
      vertical-align: middle;
    }

    .Field-label-wrap {
      width: 35%;
      text-align: right;
    }

    .Field-label {
      padding-right: $-size-large;
    }

    .Field-input-wrap {
      width: 65%;
    }
  }
}
