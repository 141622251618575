[data-component='Modal'] {
  @include antialiased;
  background-color: $Modal-bg-color;
  border-radius: 1px;
  bottom: $-size-small;
  box-shadow: 0 3px 9px $-shade-lighter;
  color: white;
  left: $-size-small;
  position: fixed;
  right: $-size-small;
  top: $-size-small;
}

.Modal-close {
  path {
    fill: white;
  }

  @include desktop {
    cursor: pointer;
    height: $-size-larger;
    opacity: 0.375;
    position: absolute;
    right: $-size-larger;
    top: $-size-larger;

    &:hover {
      opacity: 1;
    }
  }
}

.Modal-message {
  margin-bottom: $-size-large;
  opacity: 0.875;

  @include desktop {
    margin-bottom: $-size-larger;
  }
}

.Modal-title {
  font-size: $-font-size-large;
  font-weight: $-font-weight-4;
  margin-bottom: $-size;

  @include desktop {
    font-size: $-font-size-larger;
  }
}
