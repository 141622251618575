[data-component='DotsAnimation'] {
  text-align: center;
}

.DotsAnimation-inner {
  display: inline-block;

  &.isSolid {
    > div {
      &:nth-child(n) {
        background: $-blue-light;
      }
    }
  }

  > div {
    display: inline-block;
    width: $DotsAnimation-dots-height;
    height: $DotsAnimation-dots-height;
    border-radius: 50%;
    margin: 0 floor($DotsAnimation-dots-height / 3);
    animation-duration: $DotsAnimation-animation-time;
    animation-iteration-count: infinite;
    animation-name: loading;
    animation-timing-function: ease-in-out;
    transform-origin: center;

    &:nth-child(6) {
      background: $-orange-light;
    }

    &:nth-child(5) {
      background: $-blue-light;
      animation-delay: 100ms;
    }

    &:nth-child(4) {
      background: $-yellow-light;
      animation-delay: 200ms;
    }

    &:nth-child(3) {
      background: $-green-light;
      animation-delay: 300ms;
    }

    &:nth-child(2) {
      background: $-red-light;
      animation-delay: 400ms;
    }

    &:nth-child(1) {
      background: $-violet-light;
      animation-delay: 500ms;
    }
  }
}
