[data-component='Button'] {
  @include button-colors;
  @include cell-sizes;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  line-height: 1;
  text-decoration: none;
  vertical-align: middle;

  // states

  &:not(.round):not(.square) {
    @include cell-paddings(padded);
  }

  &:not(.round) {
    border-radius: 1px;
  }

  &.fluid {
    text-align: center;
    width: 100%;
  }

  @include desktop {
    cursor: pointer;

    // states

    &.isDisabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.6;

      & > * {
        pointer-events: auto;
      }
    }
  }
}
