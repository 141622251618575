// bulk

*,
*:after,
*:before {
  box-sizing: border-box;
}

a,
button,
input,
textarea {
  &:focus {
    outline: none;
  }
}

input,
select,
textarea {
  color: inherit;
}

// single

* {
  margin: 0;
  padding: 0;
}

a {
  color: $-blue;
  text-decoration: none;
}

html {
  body {
    @include texture(dots);
    width: 100%;
    height: 100%;
    font-family: $-font;
    font-size: $-size;
    color: $-gray-dark;
  }
}

button {
  background-color: transparent;
  font-family: inherit;
}

code {
  @include antialiased;
  font-size: floor($-size * (7 / 8));
  font-weight: bold;
}

img {
  display: block;
}

input,
textarea {
  display: block;
  font-family: $-font;
}

strong {
  font-weight: $-font-weight-3;
}

textarea {
  height: auto !important;
}

ul {
  list-style-type: none;
}
