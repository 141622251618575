[data-component='ScrollTop'] {
  @include mobile {
    > div {
      bottom: $-size-large !important;
      height: $ScrollTop-height !important;
      right: calc(50% - 30px) !important;
      width: $ScrollTop-height !important;

      &::before {
        @include pseudo(true);
        background-color: $-shade-darker;
        background-image: url('/vector/outline_arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 60%;
        border-radius: 50%;
        font-size: round($ScrollTop-height / 2);
        line-height: $ScrollTop-height;
        opacity: 0.5;
        text-align: center;
        transform: rotate(-90deg);
      }

      svg {
        height: $ScrollTop-height;
        opacity: 0;
        width: $ScrollTop-height;
      }
    }
  }
}
