[data-component='Auth'] {
  div[type='lock']:first-child {
    background-color: $-primary-active !important;
  }
}

[data-component='LockAnimation'] {
  text-align: center;
}

.LockAnimation-bg {
  position: relative;
  z-index: 1;
}

.LockAnimation-fg,
.LockAnimation-shine {
  @include overlay;
}

.LockAnimation-fg {
  animation-name: turnTheKnob;
  animation-duration: 7.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.LockAnimation-fg-wrap {
  @include absolute-horizontal;
  bottom: 0;
  height: $LockAnimation-width;
  width: $LockAnimation-width;

  &::before {
    @include pseudo;
    background-color: $-teal;
    border-radius: 50%;
    bottom: 3px;
    left: 3px;
    position: absolute;
    right: 3px;
    top: 3px;
  }

  img {
    position: relative;
    z-index: 1;
  }
}

.LockAnimation-icon {
  display: inline-block;
  vertical-align: top;
  width: $LockAnimation-width;
  position: relative;

  img {
    height: 100%;
  }
}

.LockAnimation-shine {
  z-index: 2;
}

@keyframes turnTheKnob {
  0% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(174deg);
  }
  15% {
    transform: rotate(174deg);
  }
  30% {
    transform: rotate(-386deg);
  }
  35% {
    transform: rotate(-386deg);
  }
  45% {
    transform: rotate(-205deg);
  }
  50% {
    transform: rotate(-205deg);
  }
  60% {
    transform: rotate(-253deg);
  }
  68% {
    transform: rotate(-253deg);
  }
  81% {
    transform: rotate(101deg);
  }
  85% {
    transform: rotate(101deg);
  }
  95% {
    transform: rotate(0deg);
  }
}
