.AssignmentsHeader {
  position: relative;
  text-align: center;

  @include desktop {
    height: $AssignmentsHeader-height;
  }

  @include mobile {
    margin-bottom: $-size-small;
  }
}

.AssignmentsHeader-inner {
  background-color: white;
  box-shadow: 0 1px 3px $-shade;
  transition: background-color $-animation-speed, box-shadow $-animation-speed;
}

.AssignmentsHeader-link {
  color: $-gray-lighter;
  display: block;
  font-size: round($-font-size * 1.125);
  letter-spacing: 0.5px;
  line-height: 1.125;
  padding: $-size-smallest $-size-large;
  text-transform: uppercase;

  &.active {
    color: $-blue;
    cursor: default;
  }

  @include desktop {
    display: inline-block;
    font-size: $-font-size-small;
    line-height: $AssignmentsHeader-height;
    padding-bottom: 0;
    padding-top: 0;

    &:hover {
      &:not(.active) {
        color: $-gray;
      }
    }
  }
}

.AssignmentsHeader-link-wrap {
  @include mobile {
    padding: 0.25em $-size-large;

    &:first-child {
      padding-top: 1em;
    }

    &:last-child {
      padding-bottom: 1em;
    }
  }

  @include desktop {
    display: inline-block;
    vertical-align: top;
  }
}

// states

.AssignmentsHeader-inner.sticky {
  background-color: rgba($Nav-bg-color, 0.925);
  box-shadow: none;
  left: 0;
  position: fixed;
  width: 100%;
  top: 0;

  .AssignmentsHeader-link {
    @include antialiased;
    color: rgba(white, 0.75);

    &.active {
      color: $-blue-neon;
    }
  }

  @include desktop {
    .AssignmentsHeader-link {
      &:hover {
        &:not(.active) {
          color: white;
        }
      }
    }
  }
}
